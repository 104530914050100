import React, { useEffect, useState } from "react";
import "./App.scss";
import "./styles/UtilClasses.scss";
import "./styles/DesignTokenClasses.scss";
import BuilderPage from "./layouts/BuilderPage";
import DataController from "./DataController";
import { ThreeSkyeWrapper } from "@threeskye/global";
import { withRouter } from "react-router";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Authorise from "./Authorise";
import Extensions from "./Extensions";
import NoAccess from "./NoAccess";
import MenuWrapper from "./layouts/MenuWrapper";
import CraigsLibraryPage from "./layouts/CraigsLibraryPage/CraigsLibraryPage";
import AdminPage from "./layouts/AdminPage/AdminPage";
import DistributionList from "./layouts/CompanyUserManagement/Distribution/DistributionList";
import SettingsProfile from "./layouts/Settings/Profile/SettingsProfilePage";
import SettingsGeneralPage from "./layouts/Settings/General/SettingsGeneralPage";
import UsersLists from "./layouts/CompanyUserManagement/User/UsersLists";
import UserPage from "./layouts/CompanyUserManagement/User/UserPage";
import CompanyLists from "./layouts/CompanyUserManagement/Company/CompanyLists";
import CompanyPage from "./layouts/CompanyUserManagement/Company/CompanyPage";
import ToastContainer from "./components/popups/ToastContainer";
import DistributionListPage from "./layouts/CompanyUserManagement/Distribution/DistributionListPage";
import ErrorPage from "./layouts/ErrorPage";
import DeliveryDetails from "./layouts/CraigsLibraryPage/DeliveryDetails";
import SuppressionList from "./layouts/CompanyUserManagement/Distribution/SuppressionList";
import PublicationView from "./layouts/CraigsLibraryPage/PublicationManagement/PublicationView";
// Analytics
import AnalyticsPage from "./layouts/Analytics/AnalyticsPage";
import AnalyticsTickersList from "./layouts/Analytics/Tickers/AnalyticsTickersList";
import AnalyticsGroupList from './layouts/Analytics/Tickers/AnalyticsGroupList';
import AnalyticsTickerPage from "./layouts/Analytics/Tickers/AnalyticsTickerPage"
import AnalyticsTickerResearchViews from "./layouts/Analytics/Tickers/AnalyticsTickerResearchViews"
import AnalyticsUsersList from "./layouts/Analytics/Users/AnalyticsUsersList";
import AnalyticsUserPage from "./layouts/Analytics/Users/AnalyticsUserPage";
import AnalyticsUserResearchViews from "./layouts/Analytics/Users/AnalyticsUserResearchView"
import AnalyticsCompaniesList from "./layouts/Analytics/Companies/AnalyticsCompaniesList";
import AnalyticsCompanyPage from "./layouts/Analytics/Companies/AnalyticsCompanyPage";
import DraftsLibrary from "./layouts/Drafts/DraftsLibrary";
import RequestsLibrary from "./layouts/Drafts/RequestsLibrary";
import { unimpersonate, useStorage, hasFeature, hasOneOfFeatures, useRemote, withRemote } from "./Utils/Utils";
import LoadingIcon from "./components/LoadingIcon";
import AnalyticsAuthorsList from "./layouts/Analytics/Authors/AnalyticsAuthorsList";
import AnalyticsAuthorPage from "./layouts/Analytics/Authors/AnalyticsAuthorPage";
import NoPermissionsPage from "./layouts/NoPermissionsPage";
import CheckAuthorisation from "./CheckAuthorisation";


export const PAGES = {
	EDITOR: "editor",
	LIBRARY: "library",
	DRAFTS: "drafts",
	ADMIN: "admin",
	CRM: "crm",
	ANALYTICS: "analytics",
	DRAFTS: "drafts",
	REQUESTS: "requests"
};

export const ROUTE_PATHS = {
	[PAGES.EDITOR]: "/editor",
	[PAGES.LIBRARY]: "/",
	[PAGES.DRAFTS]: "/drafts",
	[PAGES.ADMIN]: "/admin",
	[PAGES.CRM]: "/crm",
	[PAGES.SETTINGS]: "/settings",
	[PAGES.ANALYTICS]: "/analytics",
	[PAGES.DRAFTS]: "/drafts",
	[PAGES.REQUESTS]: "/requests"

};

const AuthIntercept = (props) => {

	const [authenticated, setAuthenticated] = useState(false);
	const [me, setMe] = useState(null);
	const [topMessage, setTopMessage] = useState(null);
	const [organisation, setOrganisation] = useState(null);
	const [loadingRole, setLoadingRole] = useState(true);

	const remote = useRemote();

	useEffect(() => {
		if (!authenticated) {
			return;
		}
		remote.registerLogoutHandler(handleLogout);
		remote.get("/crm/company").then(setOrganisation);
		remote.get("/crm/me").then((me) => {
			setMe(me);
			setLoadingRole(false);
		});
	}, [authenticated])

	const handleLogout = () => {
		setAuthenticated(false);
	}

	if (!authenticated || loadingRole) {
		return <CheckAuthorisation setAuthorised={setAuthenticated} />
	}
	const userHasNoPermissions = me === null || (me && me.perms && me.perms.length < 1)

	return (
		<MenuWrapper topComponent={topMessage} user={me} updateUser={setMe} noPermissions={userHasNoPermissions} organisation={organisation}>
			<Switch>
				{userHasNoPermissions && < Route path="*" component={NoPermissionsPage} noPermissions={userHasNoPermissions} />}
				<Route
					exact
					path={ROUTE_PATHS[PAGES.EDITOR]}
					render={() => (
						<ProtectedRoute user={me} requiredFeature={"EDIT"}>
							<DataController internalApi={true} setTopMessage={setTopMessage}>
								<BuilderPage organisation={organisation} setTopMessage={setTopMessage} />
							</DataController>
						</ProtectedRoute>
					)}
				/>

				{/* Settings and Profile Pages */}
				<Route path={ROUTE_PATHS[PAGES.SETTINGS] + "/profile"} component={SettingsProfile} />
				<Route path={ROUTE_PATHS[PAGES.SETTINGS] + "/general"} component={SettingsGeneralPage} />

				{/* Drafts Workspace/Requests Routes */}
				<Route exact path={ROUTE_PATHS[PAGES.DRAFTS]} component={hasOneOfFeatures(["DRAFTS_ANALYST", "DRAFTS_PEER"], DraftsLibrary, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.REQUESTS]} component={hasFeature("DRAFTS_COMPLIANCE", RequestsLibrary, me)} />

				{/* Analytics */}
				<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/dashboard"} component={hasFeature("ANALYTICS_VIEW", AnalyticsPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/authors"} component={hasFeature("ANALYTICS_VIEW", AnalyticsAuthorsList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers"} component={hasFeature("ANALYTICS_VIEW", AnalyticsTickersList, me)} />
				<Route
					exact
					path={ROUTE_PATHS[PAGES.ANALYTICS] + "/authors/author/:authorId"}
					render={(props) =>
						<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
							<AnalyticsAuthorPage datatita={props} />
						</ProtectedRoute>
					} />
				<Route
					exact
					path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/:tickerId"}
					render={(props) =>
						<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
							<AnalyticsTickerPage datatita={props} />
						</ProtectedRoute>
					} />
				<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group"} component={hasFeature("ANALYTICS_VIEW", AnalyticsGroupList, me)} />
				<Route
					exact
					path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group/:groupId"}
					render={(props) =>
						<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
							<AnalyticsTickerPage datatita={props} />
						</ProtectedRoute>
					}
				/>
				<Route
					path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/:tickerId/research/:researchId"}
					render={(props) =>
						<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
							<AnalyticsTickerResearchViews datatita={props} />
						</ProtectedRoute>
					} />
				<Route
					path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group/:groupId/research/:researchId"}
					render={(props) =>
						<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
							<AnalyticsTickerResearchViews datatita={props} />
						</ProtectedRoute>
					} />
				<Route
					// exact
					path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/:userId/research/:researchId"}
					render={(props) =>
						<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
							<AnalyticsUserResearchViews datatita={props} />
						</ProtectedRoute>
					} />

				<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users"} component={hasFeature("ANALYTICS_VIEW", AnalyticsUsersList, me)} />
				<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/:userId"} component={hasFeature("ANALYTICS_VIEW", AnalyticsUserPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/companies"} component={hasFeature("ANALYTICS_VIEW", AnalyticsCompaniesList, me)} />
				<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/companies/company/:companyId"} component={hasFeature("ANALYTICS_VIEW", AnalyticsCompanyPage, me)} />

				{/* Admin Routes - subpages contained inside <AdminPage /> */}
				<Route
					path={ROUTE_PATHS[PAGES.ADMIN]}
					render={() =>
						<ProtectedRoute user={me} requiredFeature={["TICKERS", "MODEL_PORTFOLIOS", "SECTORS", "DISCLAIMER", "USER_MANAGEMENT", "COMPANY_MANAGEMENT", "ROLES", "TEMPLATES"]} >
							<AdminPage me={me} />
						</ProtectedRoute>
					} />

				{/* CRM Routes */}
				<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/users"} component={hasFeature("USERS", UsersLists, me)} />
				<Route path={ROUTE_PATHS[PAGES.CRM] + "/users/user/:userId"} component={hasFeature("USERS", UserPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/companies"} component={hasFeature("COMPANIES", CompanyLists, me)} />
				<Route path={ROUTE_PATHS[PAGES.CRM] + "/companies/company/:companyId"} component={hasFeature("COMPANIES", CompanyPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/distribution"} component={hasFeature("DISTRIBUTION", DistributionList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/:distributionListId"} component={hasFeature("DISTRIBUTION", DistributionListPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/suppressions"} component={hasFeature("SUPPRESSED_EMAILS", SuppressionList, me)} />

				{/* Library Pages */}
				<Route path={ROUTE_PATHS[PAGES.CRM] + "/delivery/:deliveryId"} component={DeliveryDetails} />
				<Route exact component={hasFeature("LIBRARY_VIEW", CraigsLibraryPage, me)} />
				{/* <Route path={ROUTE_PATHS[PAGES.LIBRARY] + "publication/:publicationId"} component={PublicationView} /> */}

				{/* 404/error page */}
				<Route path="*" component={ErrorPage} />
			</Switch>
		</MenuWrapper>
	);
}

const ProtectedRoute = ({ user, requiredFeature, children }) => {

	if (Array.isArray(requiredFeature)) {
		if (user && user.perms && requiredFeature.some(f => { return user.perms.includes(f) })) {
			return children;
		}
	} else if (user && user.perms && user.perms.includes(requiredFeature)) {
		return children;
	}
	console.log("Protected route and don't have requiredFeature ", requiredFeature)

	return <ErrorPage />
};

const ImpersonationWrapper = ({ remote, ...others }) => {
	const storage = useStorage();

	const logout = (storage) => {
		unimpersonate(storage);
	}

	console.log("Impersonation wrapper has remote, router ", remote, others)

	return <AuthIntercept logout={() => logout(storage)} remote={remote} {...others} />
}


const InternalApiApp = () => {

	const remote = useRemote();

	const component = window.sessionStorage.getItem("3skye.auth.super.token") !== null ? ImpersonationWrapper : AuthIntercept;
	const hocd = withRemote(withRouter(component));

	return (
		<ThreeSkyeWrapper disableI18n={true}>
			<ToastContainer />
				<BrowserRouter>
					<Switch>
						{/* <Route exact path="/authorise/:token" component={Authorise} />
						<Route exact path="/authorise" component={CheckAuthorisation} />
						<Route path="/ext/:comp" component={Extensions} /> */}
						<Route path="/no-access" component={NoAccess} />
						<Route component={hocd} />
					</Switch>
				</BrowserRouter>
		</ThreeSkyeWrapper>
	);
}

export default (InternalApiApp);
