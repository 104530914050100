import React, { Component } from "react";
import Page, { PageContentBodyGrid } from "../../Shared/Page/Page";
import ExpandingDropzone from "../../../components/Dropzone/ExpandingDropzone";
// import UploadedDisclaimer from "./UploadedDisclaimer";
import "./AdminCompanyDisclaimersPage.scss";
import PageGridItem from "../../Shared/Page/PageGridItem";
import { withRemote } from "../../../Utils/Utils";

class AdminCompanyDisclaimersPage extends Component {
	state = { uploadingDisclaimer: false, disclaimerFile: null };
	constructor(props) {
		super(props);
		this.onDrop = this.onDrop.bind(this);
	}

	componentDidMount() {
		this.props.remote
			.get("/setup/disclaimer.pdf", { rawResponse: true })
			.then((response) => response.arrayBuffer())
			.then((arrayBuffer) => this.setState({ disclaimerFile: btoa(String.fromCharCode(...new Uint8Array(arrayBuffer))) }));
	}

	async onDrop(files) {
		if (files && files.length > 0) {
			this.setState({ uploadingDisclaimer: true, disclaimerFile: null });

			files[0].arrayBuffer().then((arrayBuffer) => {
				const uInt8Array = new Uint8Array(arrayBuffer);
				this.setState({ uploadingDisclaimer: false, disclaimerFile: btoa(String.fromCharCode(...uInt8Array)) });
				this.props.remote.post("/setup/disclaimer", { file: [...uInt8Array] });
			});
		}
	}

	render() {
		const { uploadingDisclaimer, disclaimerFile } = this.state;
		const { onDrop } = this;
		return (
			<Page noBanner>
				<PageContentBodyGrid id="admin-disclaimer-page">
					<PageGridItem colSpan="7">
						<div className="upload-section">
							{/* {disclaimerFile && <UploadedDisclaimer disclaimerFile={{ "Disc" }} />} */}
							<ExpandingDropzone expandedZoneClass="" smallZoneClass="" onDrop={onDrop} uploading={uploadingDisclaimer} showUploadingSpinner={true} uploadingMessage="Uploading..." >
							<div className="uploaded-file-container">
								{disclaimerFile && (
									<iframe title="uploaded file" src={`data:application/pdf;base64,${disclaimerFile}`} style={{ width: "100%", height: "100%" }}></iframe>
								)}
							</div>
							</ExpandingDropzone>
						</div>
					</PageGridItem>
				</PageContentBodyGrid>
			</Page>
		);
	}
}

export default withRemote(AdminCompanyDisclaimersPage);
