import React from 'react';
import Tooltip from '../popups/Tooltip';
import "./TableActionIcon.scss";

const TableActionIcon = (props) => {
	const { icon, toolTip, onClick, disabled, className, colour, hover, style, noMargin, toolTipDisabled, delay} = props;

	return (
		<div className={`table-action-icon-wrapper${disabled ? " table-action-icon-disabled" : ""}${className ? " " + className : ""}`} onClick={disabled ? () => null : onClick} style={{...style, margin: noMargin ? 0 : ''}}>
			{toolTip ? (
				<Tooltip label={toolTip} disabled={toolTipDisabled} delay={300}>
					<div className={`table-action-icon${colour ? " table-action-icon-" + colour : ""}${hover ? " hover-" + hover : ""}`}>{icon}</div>
				</Tooltip>
			) : (
				<div className={`table-action-icon${colour ? " table-action-icon-" + colour : ""}${hover ? " hover-" + hover : ""}`}>{icon}</div>
			)}
		</div>
	);
}

export default TableActionIcon;