import React, { useEffect, useState } from 'react';
import { useRemote } from '../../../Utils/Utils';
// import { useLocation } from "react-router";
import { ChevronUp, ChevronDown } from 'react-feather';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Table from '../../../components/Table/Table';
import SearchTermFilter from '../../../components/Input/SearchTermFilter'
import withRouteChange from "@threeskye/route-change"
import Button from '../../../components/Buttons/Button';
import './StyleGuide.scss'


const SubBrandsList = (props) => {
    const { changeRoute } = props
    const remote = useRemote();
    // const location = useLocation();
    const [loading, setLoading] = useState(true)
    const [hasSearchTerm, setHasSearchTerm] = useState(false);
    const [list, setList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [listDisplayed, setListDisplayed] = useState([])
    const [sortingColumn, setSortingColumn] = useState({ column: 'SubBrand', ascending: true })

    useEffect(() => {
        remote.get('/brand/all').then(fetchedBrands => {
            const subBrandsList = fetchedBrands && fetchedBrands.length > 0 && fetchedBrands.filter(b => !b.master)
            if (subBrandsList. length > 0) {
                setList(subBrandsList.sort((a, b) => a.name.localeCompare(b.name)))
                setLoading(false)
            } 
            else {
                // For now show fake list
                // const fetchedList = [
                //     { id: 1, name: 'Hejaz Financial Services', pathName: 'hejaz', createdDate: '23/07/2024', lastEdited: '05/08/2024', creator: 'Charlie Matina' },
                //     { id: 2, name: 'My North', pathName: 'my-north', createdDate: '24/07/2024', lastEdited: '04/08/2024', creator: 'Colin Matchan' },
                // ]
                // setList(fetchedList.sort((a, b) => a.name.localeCompare(b.name)))
                setLoading(false)
            }
        })
    }, [])

    useEffect(() => {
        sortTable(null)
    }, [list, filteredList])

    const sortTable = (column) => {
        if (column === null) {
            setListDisplayed(hasSearchTerm ? filteredList : list)
            return
        }
        let tableData = hasSearchTerm ? filteredList : list
        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
        switch (column) {
            case 'SubBrand':
                tableData.sort((a, b) => ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
                break;
            case 'Creator':
                tableData.sort((a, b) => ascending ? a.creator.localeCompare(b.creator) : b.creator.localeCompare(a.creator))
                break;
            case 'Created':
                tableData.sort((a, b) => ascending ? a.createdDate.localeCompare(b.createdDate) : b.createdDate.localeCompare(a.createdDate))
                break;
            case 'Edited':
                tableData.sort((a, b) => ascending ? a.lastEdited.localeCompare(b.lastEdited) : b.lastEdited.localeCompare(a.lastEdited))
                break;
            default:
                break;
        }
        setSortingColumn({ column: column, ascending: ascending })
        setListDisplayed(tableData)
    }

    const colWidths = ['180px', '400px', '270px', '150px'];
    const headers = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable('Created')}>
                    Date Created
                    {sortingColumn && sortingColumn.column === 'Created' && sortingColumn.ascending === true ?
                        <ChevronUp className='colour-text-primary' size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === 'Created' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable('SubBrand')}>
                    Sub-Brand
                    {sortingColumn && sortingColumn.column === 'SubBrand' && sortingColumn.ascending === true ?
                        <ChevronUp className='colour-text-primary' size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === 'SubBrand' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable('Creator')}>
                    Creator
                    {sortingColumn && sortingColumn.column === 'Creator' && sortingColumn.ascending === true ?
                        <ChevronUp className='colour-text-primary' size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === 'Creator' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable('Edited')}>
                    Last Edited
                    {sortingColumn && sortingColumn.column === 'Edited' && sortingColumn.ascending === true ?
                        <ChevronUp className='colour-text-primary' size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === 'Edited' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
                    }
                </div>
            )
        }
    ];

    const dataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        const { name, id, pathName, createdDate, lastEdited, creator } = datum
        return (
            <tr key={idx + '-' + id} className='cursor-pointer' onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/brand-settings/sub-brands/' + pathName)}>
                <td>{createdDate}</td>
                <td>{name}</td>
                <td>{creator}</td>
                <td>{lastEdited}</td>
            </tr>
        );
    });

    return (
        <Page noBanner noOverflownY style={{ paddingLeft: '1rem' }}>
            <PageContentBodyGrid id='client-details-page' rowGap='m' gridColCount='4' paddingTop='0rem' subGrid noOverflownY>
                <PageGridItem colSpan='12' style={{ paddingTop: '1.5rem', display: 'flex', gap: '1.5rem' }}>
                    <SearchTermFilter
                        size='small'
                        width='l'
                        placeholder={`Search sub-brands ${loading ? '' : '(' + list.length + ')'}`}
                        isClearable
                        dataSet={list}
                        setFilteredData={setFilteredList}
                        path={['name', 'creator']}
                        hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
                    />
                    <Button disabled>New Sub-brand</Button>
                </PageGridItem>
                <PageGridItem colSpan="12" style={{ paddingTop: "2rem" }}>
                    <Table
                        minWidth={1000}
                        tableStyle={{ maxWidth: 1200 }}
                        colWidths={colWidths}
                        headers={headers}
                        dataMap={dataMap(listDisplayed)}
                        noResults={{ message: "No results found" }}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page >
    )
}

export default withRouteChange(SubBrandsList);