import React, { useEffect, useRef, useState } from 'react';
import { BookOpen, Trash } from 'react-feather';
import Table from '../../../components/Table/Table';
import PageGridItem from '../Page/PageGridItem';
import TableActionIcon from '../../../components/Table/TableActionIcon';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import DetailSectionHeader from '../DetailSection/DetailSectionHeader';
import SearchField from '../../../components/Input/SearchField';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import TickerSectorItem from '../../../components/TickerSectorItem';
import FlexWrapper from '../../FlexWrapper';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';

const MiniDistributionList = ({ editMode, joined, userLists, allLists, members, userId, setData }) => {
	const colWidths = [80 + "%", 19 + "%"];
	const { width } = useWindowDimensions();
	const [filteredUserLists, setFilteredUserLists] = useState([]);
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [addableLists, setAddableLists] = useState([])
	const searchSelect = useRef();
	const remote = useRemote()

	useEffect(() => {
		let mappedLists = allLists && allLists.map((list) => { return { value: list, label: list.name } })
		setAddableLists(mappedLists)
	}, [allLists])

	const addList = (list) => {
		remote.put(`/crm/lists/${list.listId}/members/${userId}`)
			.then((response) => {
				if (response.success) {
					toastSuccess('Distribution list added')
					setData([...userLists, list])
				} else {
					toastDanger('Distribution list could not be added')
				}
			})
	}

	const removeList = (list) => {
		remote.delete(`/crm/lists/${list.id}/members/${userId}`)
			.then((response) => {
				toastSuccess("Removed from list");
				let newArray = []
				userLists.forEach((listFromArray) => {
					if (listFromArray.id !== list.id) {
						newArray.push(listFromArray)
					}
				})
				setData(newArray)
			})
			.catch(() => toastDanger('Distribution list could not be removed'))
	}


	const dataMap = (userListsToMap) => userListsToMap && userListsToMap.map((list, idx) => {
		return (
			<tr key={idx} className={editMode ? "" : "cursor-pointer"}>
				<td>
					<div className="truncate">
						<TickerSectorItem label={list.name} extensionLabel={list.type} />
					</div>
				</td>
				<td className="table-action-icons transition-fade-in-slow">

					<TableActionIcon
						icon={<Trash />}
						toolTip="Remove user from list"
						onClick={(e) => {
							e.stopPropagation();
							removeList(list);
						}}
					/>
				</td>
			</tr>
		);
	})

	const listLength = userLists && userLists.length;
	const noData = !userLists || listLength <= 0;
	const noSearchResults = false;
	const xl = width > 2250;

	return (
		<>
			<PageGridItem col="1 / span 6" justifyContent="between" gap="m" wrap>
				<DetailSectionHeader icon={<BookOpen />} header={`Distribution Lists (${noData ? "0" : listLength})`} />
				<FlexWrapper gap="s">
					{!noData && (
						<SearchTermFilter
							size="small"
							width="m"
							placeholder="Search lists"
							isClearable
							dataSet={userLists}
							setFilteredData={setFilteredUserLists}
							path={["createdBy", "createdDate", "name"]}
							hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
						/>
					)}
					<SearchField
						options={addableLists}
						onChange={(list) => addList(list.value)}
						placeholder={`Add list`}
						size="small"
						width={noData && xl ? "l" : "m"}
						openMenuOnFocus
						innerRef={searchSelect}
						value=""
					/>
				</FlexWrapper>
			</PageGridItem>
			<PageGridItem col="1 / span 6">
				{noData ? (
					<SuggestionMessage
						message={
							<>
								No lists added yet. <span className="text-link" onClick={() => searchSelect && searchSelect.current.focus()}>Try adding one now.</span>
							</>
						}
					/>
				) : noSearchResults ? (
					<NoResultsMessage compact />
				) : (
					<Table colWidths={colWidths} dataMap={dataMap(hasSearchTerm ? filteredUserLists : userLists)} />
				)}
			</PageGridItem>
		</>
	);
}

export default MiniDistributionList;