import React, { useState, useEffect } from 'react';
import { withRemote } from '../../Utils/Utils';
import PopupMenu from '../PopupMenu';
import './NewMenu.scss';
import PopupMenuHeader from '../popups/PopupMenuHeader';
import PopupMenuItem from '../PopupMenuItem';
import Divider from '../Divider';
import { useStorage } from '../../Utils/Utils';

const NewMenu = ({ changeRoute, editorPath, remote, crmPath, setShowConfirmOptionModal }) => {
	const [templates, setTemplates] = useState([])
	// const [loading, setLoading] = useState(true)
	const [me, setMe] = useState(null);
	const storage = useStorage();

	useEffect(() => {
		remote.get("/data/templates").then((templates) => {
			setTemplates(templates)
			// setLoading(false)
		})
		storage.getOrFetch("/crm/me").then(setMe);
	}, [])

	const menuItems = templates.map((template) => {
		return { name: template, onClick: () => { changeRoute(`${editorPath}`) } }
	})
	return (
		<>
			<PopupMenu padding width="240px">
				{me && me.perms && me.perms.includes("EDIT") &&
					<>
						<PopupMenuHeader>Templates</PopupMenuHeader>
						{menuItems && menuItems.map((item, idx) => <PopupMenuItem key={idx} label={item.name ? item.name : item} icon={item.icon && item.icon} onClick={item.onClick} />)}
						<Divider />
					</>
				}
				{me && me.perms && (me.perms.includes("USERS") || me.perms.includes("COMPANIES") || me.perms.includes("DISTRIBUTION")) &&

					<PopupMenuHeader>Client Management</PopupMenuHeader>
				}{me && me.perms && me.perms.includes("USERS") &&
					<PopupMenuItem label="User" onClick={() => changeRoute(`${crmPath}/users/user/create`)} />
				}{me && me.perms && me.perms.includes("COMPANIES") &&
					<PopupMenuItem label="Company" onClick={() => changeRoute(`${crmPath}/companies/company/create`)} />
				}{me && me.perms && me.perms.includes("DISTRIBUTION") &&
					<PopupMenuItem label="Distribution List" onClick={() => changeRoute(`${crmPath}/distribution/distribution-list/create`)} />
				}{me && me.perms && (me.perms.includes("USERS") || me.perms.includes("COMPANIES") || me.perms.includes("DISTRIBUTION")) &&
					<Divider />
				}
				<PopupMenuHeader>Data</PopupMenuHeader>
				<PopupMenuItem label="Synchronise Data" onClick={() => setShowConfirmOptionModal(true)} />
			</PopupMenu>
		</>
	);
}

/* `export default withRemote(NewMenu);` is exporting the `NewMenu` component with additional
functionality provided by the `withRemote` higher-order component. The `withRemote` function is
likely enhancing the `NewMenu` component with remote data fetching capabilities or other
remote-related functionalities. This pattern is commonly used in React applications to enhance
components with additional features or data fetching logic without directly modifying the component
itself. */
export default withRemote(NewMenu);