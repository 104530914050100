import React from 'react';
import AnalyticsCounter from '../../../components/Analytics/AnalyticsCounter';
import FlexWrapper from '../../FlexWrapper';
import UserChart from '../Charts/UserChart';
import { useRemote } from '../../../Utils/Utils';
import { useEffect } from 'react';
import { useState } from 'react';
import { format, lastDayOfMonth, startOfMonth, sub, differenceInMonths } from 'date-fns';
import LoadingIcon from '../../../components/LoadingIcon';

const UsageMetrics = ({ timeFrame }) => {

	const remote = useRemote();

	const [metrics, setMetrics] = useState(null);
	const [chartDates, setChartDates] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
		if (typeof timeFrame === "string") {
			const endDate = new Date()
			const startDate = new Date(timeFrame)
			const monthsDifference = differenceInMonths(endDate, startDate);

			const monthsAgo = Array.from({ length: monthsDifference }, (_, i) => i).reverse()
			setChartDates(monthsAgo.map(v => sub(endDate, { months: v })))
		} else {
			const monthsAgo = timeFrame && timeFrame.months && timeFrame.months > 0 ? Array.from({ length: timeFrame.months }, (_, i) => i).reverse() : timeFrame.years ? Array.from({ length: timeFrame.years * 12 }, (_, i) => i).reverse() : [5, 4, 3, 2, 1, 0];
			const date = new Date();
			setChartDates(monthsAgo.map(v => sub(date, { months: v })))
		}
	}, [timeFrame])

	useEffect(() => {
		Promise.all(
			chartDates.map(workingDate => {
				const start = startOfMonth(workingDate);
				const end = lastDayOfMonth(workingDate);
				return remote.get(`/analytics/aggregates/sessions?from=${format(start, 'yyyy-MM-dd')}&to=${format(end, 'yyyy-MM-dd')}`)
			})
		).then(results => {
			const list = [];
			for (var i = 0; i < results.length; i++) {
				list.push({
					name: format(chartDates[i], "MMM yyyy"),
					users: results[i].length
				})
			}
			setData(list);
			//metrics:
			const allUsers = results.flat().map(c => c.contactId);
			const unique = [...new Set(allUsers)];
			
			const myMetrics = {};
			myMetrics.totalUsers = unique.length;
			const totalLogins = results.flat().reduce((acc, curr) => curr.count + acc, 0)
			const totalMinutes = results.flat().reduce((acc, curr) => curr.minutes + acc, 0)
			
			myMetrics.loginFrequency = Math.ceil(totalLogins / unique.length)
			myMetrics.averageSessionInMinutes = Math.ceil(totalMinutes / totalLogins)
			setMetrics(myMetrics)

		})
	}, [chartDates])

	if (metrics && isNaN(metrics.averageSessionInMinutes)) return <div style={{ height: 170 }}></div>
	if (!metrics || !metrics.averageSessionInMinutes) return <LoadingIcon />;
	

	return (
		<>
			{/* <AnalyticsHeader setTimeframe={setTimeframe} timeFrame={timeFrame} dateRanges>Usage</AnalyticsHeader> */}
			<FlexWrapper fullWidth gap="xxl" className="mb-l">
				<AnalyticsCounter label="Users" count={metrics.totalUsers} />
				{/* 				<AnalyticsCounter label="New Users" count="18" /> */}
				<AnalyticsCounter label="Average Login Frequency" count={metrics.loginFrequency} />
				<AnalyticsCounter label="Average Session" count={metrics.averageSessionInMinutes + " minutes"} />
			</FlexWrapper>
			{/* Chart Here */}
			<UserChart data={data || []} />
		</>
	);
}

export default UsageMetrics;