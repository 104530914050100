import React, { useEffect, useState } from "react";
import { scaleLinear } from "d3-scale";
import * as d3 from 'd3';
import mapData from './data.csv';
import features from './features.json';
import { ComposableMap, Geographies, Geography, Sphere, Graticule, ZoomableGroup } from "react-simple-maps";


const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range(["#DFF0FF", "#0E8EFD"])
  .clamp(true);

const MapChart = ({setTooltipContent, userCountByCountry}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    d3.csv(mapData).then((data) => {
		let arr = [...data]
		let usersArray = userCountByCountry.map((element) => {
			return element.value;
		})
		let max = Math.max(...usersArray);
		userCountByCountry.forEach(element => {
			const country = arr.find((s) => {
				return s.Name === element.name
			});
			if (country) {
				country["Readers"] = element.value/max;
				country["TotalReaders"] = element.value;
			}
			
		});
      setData(data);
	});
  }, [userCountByCountry]);
	
  return (
		<ComposableMap height={400} style={{width: "100%"}} projection="geoMercator">
			<ZoomableGroup center={[30, 0]}>
				<Sphere stroke="#DFF0FF" strokeWidth={0.5} />
				<Graticule stroke="#DFF0FF" strokeWidth={0.5} />
				{data.length > 0 && (
					<Geographies geography={features}>
						{({ geographies }) =>
							geographies.map((geo) => {
								const d = data.find((s) => s.ISO3 === geo.id);
								return (
									<Geography
										onMouseEnter={() => {
											// console.log(geo, d)
											setTooltipContent(`${geo.properties.alternativeName || geo.properties.name} - ${d && d["TotalReaders"] ? d["TotalReaders"] : 0} Readers `);
										}}
										onMouseLeave={() => {
											setTooltipContent("");
										}}
										key={geo.rsmKey}
										geography={geo}
										fill={d ? colorScale(d["Readers"]) : "#DFF0FF"}
										style={{
											hover: {
											  fill: "#0A70C9",
											  outline: "none"
											},
										  }}
									/>
								);
							})
						}
					</Geographies>
				)}
			</ZoomableGroup>
		</ComposableMap>
  );
};

export default MapChart;
