import React, { Component } from "react";
import PhoneNumberEdit from "./PhoneNumberEdit";
import { retrievePhonePrefixList, testInternationalNumberSeperately } from "../../../Utils/validationUtils";
import TextFieldLabel from "../../../components/Input/TextFieldLabel";
import { withRemote } from "../../../Utils/Utils";

class PhoneNumberDetailField extends Component {
	constructor(props) {
		super(props);
		this.validate = this.validate.bind(this);
	}

	componentDidMount() {
		const { onChange, value } = this.props;
		onChange(value, this.validate(value));
	}

	validate(phone) {
		if (phone) {
			let { idd, number } = phone;
			if (idd && idd.replace) {
				idd = idd.replace("+", "");
			}
			if ((idd === "" || idd === null) && (number === "" || number === null)) { this.props.setValid && this.props.setValid(true); return true}


			let valid = testInternationalNumberSeperately("+" + idd, number)

			this.props.setValid && this.props.setValid(valid)
			return valid;
		} else {
			this.props.setValid && this.props.setValid(true)
			return true;
		}
	}

	render() {
		const { onChange, label, helperText, ...otherProps } = this.props;
		return (
			<>
				{label && <TextFieldLabel>{label}</TextFieldLabel>}
				<PhoneNumberEdit
					placeholderIDD="IDD"
					placeholderValue="Enter phone number"
					type="text"
					noPrefixMessage="Please enter a prefix using the dropdown"
					invalidNumberMessage="Please enter a valid number"
					{...otherProps}
					onChange={({ valid, ...value }) => onChange(value, valid)}
					retrievePrefixList={(entry) => retrievePhonePrefixList(entry, this.props.remote)}
					validate={this.validate}
				/>
			</>
		);
	}
}

export default withRemote(PhoneNumberDetailField);