import React, { useEffect, useState } from 'react';
import "./DataManagerPage.scss";
import { AlertCircle, CheckCircle, ChevronDown, ChevronUp } from 'react-feather';
import Table from '../../../components/Table/Table';
import { format } from 'date-fns';
import FlexWrapper from '../../FlexWrapper';
import Divider from '../../../components/Divider';

const DataUploadTable = ({ data }) => {
	const [sortingColumn, setSortingColumn] = useState({ column: "Upload Date", ascending: false })
	const [expandedRows, setExpandedRows] = useState([])

	const sortTable = (column) => {
		if (sortingColumn.column === column) {
			setSortingColumn({ column, ascending: !sortingColumn.ascending })
		} else {
			setSortingColumn({ column, ascending: true })
		}
	}

	const handleSort = (a, b) => {
		switch (sortingColumn.column) {
			case "Upload Date":
				return sortingColumn.ascending ? a.uploadedDate.localeCompare(b.uploadedDate) : b.uploadedDate.localeCompare(a.uploadedDate);
			case "Files Processed":
				return sortingColumn.ascending ? a.status.numberCompleted - b.status.numberCompleted : b.status.numberCompleted - a.status.numberCompleted;
			case "Files Failed":
				return sortingColumn.ascending ? a.status.numberFailed - b.status.numberFailed : b.status.numberFailed - a.status.numberFailed;
			case "Uploaded By":
				return sortingColumn.ascending ? a.uploadedBy.name.localeCompare(b.uploadedBy.name) : b.uploadedBy.name.localeCompare(a.uploadedBy.name);
			default:
				return 0;
		}
	};

	const colWidths = ["150px", "150px", "150px", "300px"]

	const headers = [
		{
			text: <HeaderText text="Upload Date" sortLabel="Upload Date" ascending={sortingColumn.ascending} currentSort={sortingColumn.column} sortTable={sortTable} />
		},
		{
			text: <HeaderText text="Files Processed" sortLabel="Files Processed" ascending={sortingColumn.ascending} currentSort={sortingColumn.column} sortTable={sortTable} />
		},
		{
			text: <HeaderText text="Files Failed" sortLabel="Files Failed" ascending={sortingColumn.ascending} currentSort={sortingColumn.column} sortTable={sortTable} />
		},
		{
			text: <HeaderText text="Uploaded By" sortLabel="Uploaded By" ascending={sortingColumn.ascending} currentSort={sortingColumn.column} sortTable={sortTable} />
		}
	]

	const dataMap = () => {
		return data && data.length > 0 && data.sort(handleSort).map((upload) => {
			const { numberCompleted, numberFailed, numberOfFiles } = upload.status
			return <>
				<tr className={`data-processing-table-row ${expandedRows.includes(upload.batchId) ? "expanded" : ""}`} onClick={() => expandedRows.includes(upload.batchId)
					? setExpandedRows(expandedRows.filter((val) => val !== upload.batchId))
					: setExpandedRows([...expandedRows, upload.batchId])
				}>
					<td>{format(new Date(upload.uploadedDate), "dd/MM/yyyy")}</td>
					<td><FlexWrapper align='center' gap="s">{numberCompleted === numberOfFiles ? <CheckCircle color={"#56D1C3"} /> : ""} {`${numberCompleted}/${numberOfFiles}`}</FlexWrapper></td>
					<td><FlexWrapper align='center' gap="s">{numberFailed >= 1 ? <AlertCircle color={"#E66780"} /> : ""} {`${numberFailed}/${numberOfFiles}`}</FlexWrapper></td>
					<td>{upload?.uploadedBy?.name}</td>
				</tr>
				{expandedRows.includes(upload.batchId) && (
					<tr className={`data-processing-table-row-expansion`}>
						<td colSpan={4}>
							<div className="upload-table-status-message">
								{upload.fundsStatus && upload.fundsStatus.map(
									(offer, index) => offer.succeeded && <>
										<p style={{fontSize: "12px", opacity: 0.5, lineHeight: "12px"}} >{offer.offerName}</p>
										<p key={index} style={{marginBottom: "1rem"}}>{`General Fund Data (${offer.succeeded.length}/${offer.succeeded.length + offer.missing.length})`}</p>
									</>
								)}
							</div>
							{(upload.fundsStatus && upload.fundsStatus.length > 0) ? <Divider /> : null}
							{numberFailed && numberFailed > 0 && upload.batchMessage
								? <div className="upload-table-error-message">
									<FlexWrapper align="center" gap="s" className="error-heading">
										<span>
											Missing Data
										</span>
									</FlexWrapper>
									<p style={{ whiteSpace: "pre-wrap" }} >{upload.batchMessage}</p>
								</div>
								: <div className="upload-table-status-message"><p >No Data Missing</p></div>
							}
						</td>
					</tr>
				)}
			</>
		})
	}
	return (
		<div className="data-upload-table">
			{
				data && data.length > 0
					? <Table
						minWidth={1000}
						tableStyle={{ maxWidth: 1400 }}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap()}
					/>
					: <p className="empty-upload-table-message" > There are currently no processes running</p>
			}
		</div>
	);
}

export default DataUploadTable;

const HeaderText = ({ text, sortLabel, ascending, currentSort, sortTable }) => {
	return (
		<div className='sorting-header' onClick={() => sortTable(sortLabel)} >
			{text}
			{currentSort === sortLabel && ascending === true
				? <ChevronUp className="colour-text-primary" size={12} />
				: <ChevronDown className={currentSort === sortLabel ? "colour-text-primary" : ""} size={12} />
			}
		</div>
	)
}