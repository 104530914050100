import React, { useEffect, useRef, useState } from 'react';
import Table from '../../../components/Table/Table';
import PageGridItem from '../Page/PageGridItem';
import { useRemote } from '../../../Utils/Utils';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import GenericListHeader from './GenericListHeader';
import ConfirmActionModal from '../../../components/Modals/ConfirmActionModal';


const GenericMiniList = ({ editMode, userLists, allLists, userId, setData, headerOptions, header, onHeaderOptionSelect, 
	dataMap, colWidths, onSearchSelect, addAllButton, watchList, noDataMessage, hideAddInput, allTickers, allGroups}) => {
		
	const [filteredUserLists, setFilteredUserLists] = useState([]);
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [addableLists, setAddableLists] = useState([])
	const searchSelect = useRef();
	const [showModal, setShowModal] = useState(false)

	const remote = useRemote()

	useEffect(() => {
		let mappedLists = allLists && allLists.map((list) => { return { value: list, label: list.name } })
		setAddableLists(mappedLists)
	}, [allLists])

	const addAllLists = () => {
		remote.put(`/crm/contacts/${userId}/lists/all`)
			.then((res) => {
				if (res.success) {
					toastSuccess('All Distribution Lists added.')
					setData(allLists.map((list) => { return { ...list, id: list.listId } }))
				} else {
					toastDanger('Distribution List could not be added')
				}
			})
	}

	const addList = (list) => {
		remote.put(`/crm/lists/${list.listId}/members/${userId}`)
			.then((response) => {
				if (response.success) {
					toastSuccess('Distribution List added')
					setData([...userLists, { ...list, id: list.listId }])
				} else {
					toastDanger('Distribution List could not be added')
				}
			})
	}

	const listLength = userLists && userLists.length;
	const noData = !userLists || listLength <= 0;
	const noSearchResults = false;

	return (
		<>
			{showModal && (
				<ConfirmActionModal
					action="add all distribution lists to this user"
					handleClose={() => setShowModal(false)}
					cancelButton={{ onClick: () => setShowModal(false) }}
					confirmButton={{ onClick: () => setShowModal(false, addAllLists()) }}
				/>
			)}
			<PageGridItem col="1 / span 6" justifyContent="between" gap="m" wrap>
				<GenericListHeader
					setHasSearchTerm={setHasSearchTerm}
					onSearchSelect={(list) => addList(list)}
					header={header}
					headerOptions={headerOptions}
					onHeaderOptionSelect={onHeaderOptionSelect}
					addableLists={addableLists}
					userLists={userLists}
					setFilteredUserLists={setFilteredUserLists}
					searchSelect={searchSelect}
					addAllButton={addAllButton}
					setShowModal={setShowModal}
					hideAddInput={hideAddInput}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 6">
				{noData ? (
					<SuggestionMessage
						message={noDataMessage ? noDataMessage :
							<>
								No lists added yet. <span className="text-link" onClick={() => searchSelect && searchSelect.current.focus()}>Try adding one now.</span>
							</>
						}
					/>
				) : noSearchResults ? (
					<NoResultsMessage compact />
				) : (
					<Table colWidths={colWidths} dataMap={dataMap(hasSearchTerm ? filteredUserLists : userLists, userId, setData, userLists, editMode, watchList, allTickers, allGroups)} />
				)}
			</PageGridItem>
		</>
	);
}

export default GenericMiniList;