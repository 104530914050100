import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
// import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import PageGridItem from '../../Shared/Page/PageGridItem';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import { useParams, useHistory } from 'react-router-dom';
import TemplateGeneralDetails from './TemplateGeneralDetails';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import TemplatePublishActions from './TemplatePublishActions';
import TemplateCompliance from './TemplateCompliance'
import TemplateOptions from './TemplateOptions';
import MiniSectorsList from '../../Shared/TablesAndLists/MiniSectorsList';
import TemplateCustomPublishActions from './TemplateCustomPublishActions';


const TemplateMgmt = ({ me }) => {
	// _________________ Actions list __________________
	// 1.- They have the current actions from "crm/publish-steps" but organised into three categories: Compliance, Publish Actions, and Custom Publish Actions.
	// 2.- They also have new actions that have not been created in the backend yet. Those actions are currently disabled.
	// 3.- The actions pulled from "crm/publish-steps" will need to be restructured as peer point one. That might mean 3 different endpoints, or just the same endpoint with a category attribute for each action.
	// 4.- Each action list should change according to the Template Type.
	// 5.- WARNING: Publish to Portal Action (real action that comes from "crm/publish-steps", id 7), throws a 500 for simplicity

	const complianceActions = [
		{
			id: 12,
			companyId: me?.companyId,
			displayName: 'Analyst Spot Check',
			description: 'Requires approval from a spot checker before engaging approval workflows',
			implemenation: 'AnalystSpotCheck',
			unwired: true
		},
		{
			id: 5,
			companyId: me?.companyId,
			displayName: 'Peer Approval',
			description: 'Prevents publishing if peer approval has not been given',
			implemenation: 'PeerApproval'
		},
		{
			id: 14,
			companyId: me?.companyId,
			displayName: 'Peer Comments',
			description: 'Enables peer comments',
			implemenation: 'PeerComments',
			unwired: true
		},
		{
			id: 4,
			companyId: me?.companyId,
			displayName: 'Compliance Approval',
			description: 'Prevents publishing if compliance approval has not been given',
			implemenation: 'ComplianceApproval',
		},
		{
			id: 13,
			companyId: me?.companyId,
			displayName: 'Compliance Comments',
			description: 'Enables compliance comments',
			implemenation: 'ComplianceComments',
			unwired: true
		},
		{
			id: 15,
			companyId: me?.companyId,
			displayName: 'Supervisor Approval',
			description: 'Prevents publishing if supervisor approval has not been given',
			implemenation: 'SupervisorApproval',
			unwired: true
		},
		{
			id: 16,
			companyId: me?.companyId,
			displayName: 'Management Spot Check',
			description: 'Requires approval from a spot checker before sending disclose files',
			implemenation: 'ManagementSpotCheck',
			unwired: true
		},
	]

	const publishActions = [
		{
			id: 3,
			companyId: me?.companyId,
			displayName: 'Publish to 3Skye Wealth CRM',
			description: 'Displays the published document in the 3Skye Wealth instance',
			implemenation: 'ThreeSkyeWealth'
		},
		{
			id: 7,
			companyId: me?.companyId,
			displayName: 'Publish to Portal',
			description: 'Displays the published document in the customer portal',
			implemenation: 'PublishToPortal'
		},
		{
			id: 11,
			companyId: me?.companyId,
			displayName: 'Publish Disclose Files', // 'Publish to Disclose'
			description: 'Files will be uploaded to the disclose register', // 'Publish all required documents to the Disclose Register'
			implemenation: 'PublishToPortal',
			unwired: true
		},
		{
			id: 1,
			companyId: me?.companyId,
			displayName: 'Email Author',
			description: 'Emails a copy of the published document to the author',
			implemenation: 'EmailAuthor'
		},
		{
			id: 2,
			companyId: me?.companyId,
			displayName: 'Email Distribution List',
			description: 'Emails a copy of the published document to a distribution list',
			implemenation: 'SendToDistributionList'
		}
	]

	const customPublishActions = [
		{
			id: 6,
			companyId: 1, // -> Craigs only
			displayName: 'Generate BOB2 Spreadsheet',
			description: 'Generates a BOB2 and emails to the receiving system',
			implemenation: 'Bob2'
		},
		{
			id: 8,
			companyId: me?.companyId,
			description: null,
			displayName: 'Email List',
			implemenation: 'EmailList',
			unwired: true
		},
		{
			id: 9,
			companyId: 1, // -> Craigs only
			description: 'Publish to 3RD Party Research Providers via RIXML',
			displayName: 'RIXML',
			implemenation: 'RIXML',
			unwired: true
		},
		{
			id: 10,
			companyId: me?.companyId,
			description: 'Email Success/Failure Report',
			displayName: 'Reporting',
			implemenation: 'Reporting',
			unwired: true
		}
	]
	// _________________________________________________________________________________________________________  

	const remote = useRemote();
	const history = useHistory()
	const { width } = useWindowDimensions();
	const { template: templateId } = useParams()

	const [template, setTemplate] = useState({});
	const [originalTemplate, setOriginalTemplate] = useState({})
	// const [publishActions, setPublishActions] = useState([]);    // we are using the lists above for now
	const [selectedPublishActions, setSelectedPublishActions] = useState([]);
	const [originalSelectedActions, setOriginalSelectedActions] = useState([])
	const [templateDef, setTemplateDef] = useState({});
	const [notFound, setNotFound] = useState(false);
	const [assetType, setAssetType] = useState({ label: 'Sector', value: 'Sector' })
	const [editMode, setEditMode] = useState(false)


	useEffect(() => {
		remote.get("/crm/templates/" + templateId).then((fetchedTemplate) => {
			setOriginalTemplate(fetchedTemplate)
			setTemplate(fetchedTemplate)
		}).catch(e => {
			console.log("Caught e ", e);
			setNotFound(true);
		});
		// remote.get("/crm/publish-steps").then(setPublishActions);    // we are using the lists above for now
		remote.get(`/crm/templates/${templateId}/publish-steps`).then((fetchSelectedActions) => {
			setOriginalSelectedActions(fetchSelectedActions)
			setSelectedPublishActions(fetchSelectedActions)
		});
	}, []);


	useEffect(() => {
		if (template && template.template) {
			try {
				setTemplateDef(JSON.parse(template.template))
			} catch (e) {
				console.log(e)
			}
		}
	}, [template])


	function addActionToTemplate(action) {
		remote.put(`/crm/templates/${templateId}/publish-steps/${action.id}`).then(setSelectedPublishActions);
	}

	function removeActionFromTemplate(action) {
		remote.delete(`/crm/templates/${templateId}/publish-steps/${action.id}`).then(setSelectedPublishActions);
	}

	function goBack() {
		history.push("/admin/templates")
	}

	function saveEdits() {
		setEditMode(false)
		// create real endpoint to modify the template settings
	}

	function cancelEdits() {
		setTemplate(originalTemplate)
		setEditMode(false)
	}


	if (notFound) return (
		<Page fixedBanner>
			<PageContentBanner divider>
				<ActionButtonsBanner />
			</PageContentBanner>
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				<p>Invalid template</p>
			</PageContentBodyGrid>
		</Page>
	)

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					handleBackOnClick={goBack}
					editMode={editMode}
					edit={{ onClick: () => setEditMode(true), disabled: true }}
					cancel={{ onClick: () => { cancelEdits() } }}
					save={{ onClick: () => { saveEdits() } }}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the template details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<TemplateGeneralDetails template={templateDef} setTemplate={setTemplateDef} editMode={editMode} />
						<PageGridDivider col="1 / span 6" />
						{/* Compliance */}
						<TemplateCompliance
							templateId={templateId}
							templateName={template.name}
							data={complianceActions}
							addActionToTemplate={addActionToTemplate}
							removeAction={removeActionFromTemplate}
							selected={selectedPublishActions}
							setSelectedPublishActions={setSelectedPublishActions}
							templateType={templateDef?.ticker?.templateType || "-"}
							editMode={editMode}
						/>
						{/* Publishing actions */}
						<TemplatePublishActions
							templateId={templateId}
							templateName={template.name}
							data={publishActions}
							addActionToTemplate={addActionToTemplate}
							removeAction={removeActionFromTemplate}
							selected={selectedPublishActions}
							setSelectedPublishActions={setSelectedPublishActions}
							templateType={templateDef?.ticker?.templateType || "-"}
							editMode={editMode}
						/>
						{/* Custom Publishing actions */}
						<TemplateCustomPublishActions
							templateId={templateId}
							templateName={template.name}
							data={customPublishActions}
							addActionToTemplate={addActionToTemplate}
							removeAction={removeActionFromTemplate}
							selected={selectedPublishActions}
							setSelectedPublishActions={setSelectedPublishActions}
							companyId={me?.companyId}
							editMode={editMode}
						/>
					</PageContentBodyGrid>
				</PageGridItem>
				{/* grid container for the sectors list section */}
				<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid showScrollbar rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"} paddingBottom={"3rem"}>
						<TemplateOptions template={templateDef} assetType={assetType} setAssetType={setAssetType} />
						{templateDef && templateDef.ticker && templateDef.ticker.options && (templateDef.ticker.type === 'selection' || templateDef.ticker.type === 'scheme') &&
							<>
								<PageGridItem col="1 / span 6">
									<PageGridDivider col="1 / span 6" />
								</PageGridItem>
								<PageGridItem col="1 / span 6">
									<MiniSectorsList data={templateDef.ticker && templateDef.ticker.options} assetType={assetType} stopRedirect />
								</PageGridItem>
							</>
						}
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default TemplateMgmt;