import React, { useEffect, useState } from 'react'
import Logo from '../../layouts/Shared/Logo/Logo'
import Button from '../Buttons/Button'
import { CheckCircle, CloudOff, Info, Plus } from 'react-feather'
import NewMenu from './NewMenu'
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import './TopBar.scss'
import Popup from '../Popup'
import ProfileMenu from './ProfileMenu'
import ProfileImage from '../ProfileImage'
import { useRemote, useStorage } from '../../Utils/Utils'
import { format } from 'date-fns'
import LoadingIcon from '../LoadingIcon';
import StatusWidget from './StatusWidget'
import ConfirmOptionModal from '../Modals/ConfirmOptionModal'
import { useHistory } from 'react-router-dom'

const TopBar = ({ topMessage }) => {
	const [profileMenuOpen] = useState(false);
	const [showConfirmOptionModal, setShowConfirmOptionModal] = useState(false);

	const remote = useRemote();
	const storage = useStorage();
	const history = useHistory();

	const [me, setMe] = useState(null);
	const [tenants, setTenants] = useState([]);

	useEffect(() => {
		storage.getOrFetch("/crm/me").then(setMe);
		remote.get("/crm/tenants").then(setTenants);
	}, [])

	
	const getMessageComponent = () => {
		const { noDocument, saving, lastSaved, error } = topMessage;

		if (topMessage) {
			return (
				<StatusWidget
					icon={noDocument ? <Info /> : saving ? <LoadingIcon variant="light" size={20} /> : lastSaved ? <CheckCircle className="colour-text-positive" /> : error ? <CloudOff /> : ""}
					label={noDocument ? "No document loaded" : saving ? "Saving to 3SKYE…" : lastSaved ? "Save Complete:" : error ? "Save Unavailable:" : ""}
					message={lastSaved ? <>{format(lastSaved, "hh:mm aaa, dd MMM yyyy ")}</> : error ? "Network Lost" : ""}
					status={error && !saving ? "danger" : ""}
				/>
			);
		}
		return null;
	}

	const synchroniseData = () => {
		remote.post("/data/synchronise")
	}

	return (
		<div className="top-bar colour-bg-accent-2">
			{showConfirmOptionModal &&
				<ConfirmOptionModal
					header="Data Synchronisation"
					message="Select which data you would like to synchronise:"
					handleClose={() => setShowConfirmOptionModal(false)}
					cancelButton={{ onClick: () => setShowConfirmOptionModal(null) }}
					confirmButton={{ variant: "positive", label: "Sync Data", onClick: () => { synchroniseData(); setShowConfirmOptionModal(false) } }}
					options={[{ label: "Fund Fact Sheet", checked: true, onClick: () => null, disabled: true }, { label: "Quarterly Fund Update", checked: false, onClick: () => null, disabled: true }]}
				/>
			}
			<div className="top-bar-left-container">
				<Logo className="top-bar-logo" onClick={() => {
					history.push(ROUTE_PATHS[PAGES.LIBRARY])
				}}
				/>
			</div>
			{topMessage && getMessageComponent()}
			<div className="top-bar-right-container">
				<Popup
					className="mr-l"
					triggerElement={<Button noHover className="top-bar-new-Button" onClick={() => history.push(ROUTE_PATHS[PAGES.EDITOR])} icon={<Plus />} >New</Button>}
					popupElement={<NewMenu crmPath={ROUTE_PATHS[PAGES.CRM]} editorPath={ROUTE_PATHS[PAGES.EDITOR]} changeRoute={(url) => history.push(url)} setShowConfirmOptionModal={setShowConfirmOptionModal} />}
					hoverOnly
					offsetY="2"
				/>
				<Popup
					// onClick={() => setProfileMenuOpen(!profileMenuOpen)}
					triggerElement={<ProfileImage focused={profileMenuOpen} user={me} src={me && ("data:image/jpeg;base64," + me.photo)} />}
					popupElement={<ProfileMenu changeRoute={(url) => history.push(url)} user={me} tenants={tenants} />}
					hoverOnly
				/>
			</div>
		</div>


	)
};

export default TopBar;