import React, { useEffect, useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/DateRangeOverrides.scss";
import { ArrowLeft, ArrowRight, Calendar, X } from "react-feather";
import Popup from "../../components/Popup";
import Button from "../../components/Buttons/Button";
import PopupMenu from "../../components/PopupMenu";
import ButtonGroupWrapper from "../../components/Buttons/ButtonGroupWrapper";
import moment from "moment"
import Divider from "../../components/Divider";

const DateRangeInput = ({ onChange, value, showDateRangeButton, r }) => {
	const [focusedInput, setFocusedInput] = useState(null)
	const [from, setFrom] = useState(value.from)
	const [activePreset, setActivePreset] = useState("")
	const [to, setTo] = useState(value.to)


	const presetDateSelected = (timeSpan) => {
		setTo(moment())
		setFrom(moment().subtract(timeSpan[0], timeSpan[1]))
	}

	useEffect(() => { onChange({ from, to }) }, [from, to])

	const renderDatePresets = () => {
		return (
			<div className="date-range-info-outer">
				<div className="date-range-info-inner">
					<ButtonGroupWrapper gap="s">
						<Button variant={activePreset === "1 month" ? "" : "outlined"} small onClick={() => {presetDateSelected([1, "months"]); setActivePreset("1 month")} } >1 Month</Button>
						<Button variant={activePreset === "3 months" ? "" : "outlined"} small onClick={() => {presetDateSelected([3, "months"]); setActivePreset("3 months")} } >3 Months</Button>
						<Button variant={activePreset === "1 year" ? "" : "outlined"} small onClick={() => {presetDateSelected([1, "years"]); setActivePreset("1 year")} } >1 Year</Button>
						<Divider vertical />
						<Button variant="secondary" small onClick={() => { setTo(null); setFrom(null); setActivePreset("") }}>Clear</Button>
					</ButtonGroupWrapper>
				</div>
			</div>
		)
	}

	return (
		<div className="date-range-container">
			{showDateRangeButton && (
				<Popup
					className="display-xs-none display-lg-block"
					absolute
					popupOuterWidth="100%"
					triggerElement={<Button variant="secondary">Date Range</Button>}
					popupElement={<PopupMenu menuItems={["Last 28 Days", "1 Month", "3 Months", "1 Year"]} />}
				/>
			)}
			<div className="calendar-range-wrapper">
				<DateRangePicker
					startDate={from} // momentPropTypes.momentObj or null,
					startDateId="date-range-picker-start" // PropTypes.string.isRequired,
					endDate={to} // momentPropTypes.momentObj or null,
					endDateId="date-range-picker-end" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => { setFrom(startDate); setTo(endDate) }} // PropTypes.func.isRequired,
					focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
					displayFormat={() => "DD/MM/YYYY"}
					hideKeyboardShortcutsPanel
					noBorder
					verticalSpacing={5}
					keepOpenOnDateSelect
					navPrev={
						<div className="navigation-button navigation-button__prev">
							<ArrowLeft />
						</div>
					}
					navNext={
						<div className="navigation-button navigation-button__next">
							<ArrowRight />
						</div>
					}
					isOutsideRange={() => false}
					customArrowIcon={<ArrowRight className={`arrow-icon ${from && from.isValid ? "start-date-valid" : ""}`} />}
					customInputIcon={<Calendar className="calendar-icon" />}
					renderCalendarInfo={renderDatePresets}
				/>
				{(from || to) && <X className="transition-fade-in clear-icon" onClick={() => { setTo(null); setFrom(null); setActivePreset("")}}/>}
			</div>
		</div>
	);

}

export default DateRangeInput;
