import React from "react";
import "./SidebarExtensionLink.scss";
import { useHistory } from "react-router-dom";

const SidebarExtensionLink = (props) => {
	const { link, children, active, onClick } = props;
	const history = useHistory();
	return (
		<div onClick={onClick ? onClick : () => history.push(link)} className={`sidebar-extension-link${active ? " sidebar-extension-link-active" : ""}`}>
			{children}
		</div>
	);
};

export default SidebarExtensionLink;