import React, { useEffect, useState } from 'react';
import { Edit2, Plus } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Table from '../../../components/Table/Table';
import TableActionIcon from '../../../components/Table/TableActionIcon';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { useHistory } from 'react-router-dom';

const TemplateList = ({}) => {

	const remote = useRemote();
	const history = useHistory()

	const [data, setData] = useState([]);

	useEffect(() => {
		remote.get("/crm/templates").then(setData);
	}, [])
	
	
	// Table widths and headers
	const colWidths = [40 + "%", 20 + "%", 15 + "%", 10 + "%", 15 + "%"];
	const headers = ["Template", "Ticker Type", "Optional Pages", "Outputs", ""];

	
	const dataMap = () => data && data.length > 0 && data.map((template, idx) => {
		const actualTemplate = JSON.parse(template.template);
		console.log('actualTemplate', actualTemplate)
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => history.push(ROUTE_PATHS[PAGES.ADMIN] + "/templates/" + template.id)}>
				<td>{template.name}</td>
				<td className="text-transform-capitalise">{actualTemplate && actualTemplate.ticker.type}</td>
				<td>{actualTemplate && actualTemplate.optionalPages ? "Yes" : "No"}</td>
				<td>{actualTemplate && actualTemplate.outputs ? actualTemplate.outputs.length : 1} </td>
				<td className="table-action-icons">
					<TableActionIcon icon={<Edit2 />} toolTip="Edit" />
				</td>
			</tr>
		)
	})


	return (
		<Page fixedBanner>
			<PageContentBanner divider>
				<Button icon={<Plus />} disabled title="Please contact support to discuss a new template">New Template</Button>
			</PageContentBanner>
			<PageContentBodyGrid>
				<PageGridItem colSpan="12" >
					<Table minWidth={600} colWidths={colWidths} headers={headers} dataMap={dataMap()} />
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	)
}

export default TemplateList;