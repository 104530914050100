import React, { useEffect, useState } from 'react';
import { SortableSidebarSection } from './SideBarSection';
import { useRemote, onDrop } from '../../../Utils/Utils';
import TextInput from '../../Input/TextInput';
import arrayMove from "array-move";
import TickerGroupSelector from './TickerGroupSelector';
import TickerSelector from './TickerSelector';
import AlertBlock from '../../../layouts/Shared/Prompts/AlertBlock';
import Dropzone from "../../Dropzone/Dropzone";
import { TEMPLATE_TICKER_TYPES } from './EditorSideBarExtension';
import DropDown from '../../DropDown';

const AssetSelectionSection = ({ setState, state, updateTickerStatus, showModal, updateTickerGroup }) => {
	const { selectedTickerGroup, tickers, tickerMessage, loadingLocalData, template, uploading, showUploadingSpinner, uploadingMessage } = state

	const reorderTicker = ({ oldIndex, newIndex }) => {
		document.body.style.cursor = 'default';
		setState((prevState) => {
			prevState.selectedTickerGroup.tickers = arrayMove(prevState.selectedTickerGroup.tickers, oldIndex, newIndex);
			prevState.tickers = prevState.selectedTickerGroup.tickers.filter(ticker => prevState.tickers.includes(ticker));
			return prevState;
		}, () => updateTickerStatus(true));
	};

	const uploadDataFiles = (files) => {
		onDrop(files, '/data/upload', { acceptedFileTypes: 'spreadsheetml', maxFileSize: 5 * 1024 * 1024, setLoading: (val) => setState({ uploading: val, showUploadingSpinner: val }), onUploadComplete: onUploadComplete })
	}

	const onUploadComplete = (e) => {
		//Reload data, if we've got a ticker
		const resp = JSON.parse(e.target.response);
		if (!resp.success) {
			showModal("uploadFailure");
			setState({ uploading: false, showUploadingSpinner: false, uploadingMessage: resp.message });
		} else {
			setState({ showUploadingSpinner: false, uploadingMessage: resp.message });
			setTimeout(() => setState({ uploading: false }), 1500);
			updateTickerStatus(true);
			onUpdateTickerGroup();
		}
	}

	const onUpdateTickerGroup = () => {
		console.log("onUpdateTickerGroup AssetSelectionSection");
		updateTickerGroup();
	}

	const onCheckboxTickerToggle = (ticker, checked) => {
		const newTickers = selectedTickerGroup.tickers.filter(tgTicker => tgTicker === ticker ? !checked : tickers.includes(tgTicker));
		setState({ tickers: newTickers }, updateTickerStatus);
	}

	const onTickerTextChange = (event, index, callback) => {
		console.log("On ticker text change has event ", event, 'and index ', index)
		let value = event.currentTarget.value.toUpperCase();
		setState({ tickers: [value] }, callback)
		//		setState(({ tickers }) => ({ tickers: tickers.slice(0, index).concat(value).concat(tickers.slice(index + 1, tickers.length)) }));
		console.log("Setting ticker to ", value)
		console.log("Hopefully that triggers a load??")
	}

	let component = null;

	if (template?.ticker) {
		switch (template.ticker.type) {
			case TEMPLATE_TICKER_TYPES.OPEN:
				component = <>{tickers.map((ticker, idx) => <TickerSelection onTickerTextChange={onTickerTextChange} updateTickerStatus={updateTickerStatus} ticker={ticker} idx={idx} />)}</>
				break;
			case TEMPLATE_TICKER_TYPES.SELECTION:
				component = <TickerGroupSelector
					template={template}
					setState={setState}
					onUpdateTickerGroup={onUpdateTickerGroup}
					selectedTickerGroup={selectedTickerGroup}
					tickers={tickers}
					tickerMessage={tickerMessage}
					loadingLocalData={loadingLocalData}
					onCheckboxTickerToggle={onCheckboxTickerToggle}
				/>
				break;
			case TEMPLATE_TICKER_TYPES.FUND:
				component = <FundSelectionSelector onTickerTextChange={onTickerTextChange} updateTickerStatus={updateTickerStatus} tickerOption={template.ticker} />
				break;
			case TEMPLATE_TICKER_TYPES.SCHEME:
				component = <div>
					<label className="label-input">{template.ticker.selectionName}</label>
					<DropDown clickable fullWidth value={selectedTickerGroup && selectedTickerGroup.offerName}>
						{state.selectionGroups
							.filter((option) => template.ticker?.schemeNumbers?.includes(option.offerNumber))
							.map((option, index) => (
								<li key={index} onClick={() => setState({ selectedTickerGroup: option }, onUpdateTickerGroup)}>
									{option.offerName}
								</li>
							))}
					</DropDown>
				</div>

		}
	}

	return (
		<SortableSidebarSection
			separator
			onSortStart={() => (document.body.style.cursor = "grabbing")}
			onSortEnd={reorderTicker}
			helperClass="drag-item"
		>
			{component}
			<p className="label-input mt-xl">Market Data Spreadsheet</p>

			<Dropzone
				onDrop={uploadDataFiles}
				uploading={uploading}
				showUploadingSpinner={showUploadingSpinner}
				uploadingMessage={uploadingMessage}
			/>
			{tickerMessage[tickers[0]] && (
				<AlertBlock
					justify="center"
					className="mt-m"
					type={tickerMessage[tickers[0]] !== "No data available" ? "positive" : "warning"}
					small
					fullWidth
				>
					{tickerMessage[tickers[0]]}
				</AlertBlock>
			)}
		</SortableSidebarSection>
	);
}

export default AssetSelectionSection;


const TickerSelection = ({ onTickerTextChange, updateTickerStatus, ticker, idx }) => {

	const blurOnEnter = (e) => {
		if (e.key === 'Enter') {
			e.target.blur()
		}
	}

	return <TextInput
		label="Ticker"
		// size="small"
		onChange={(e) => onTickerTextChange(e, idx)}
		onBlur={(e) => updateTickerStatus(true)}
		onKeyDown={(e) => blurOnEnter(e)}
		name="ticker"
		value={ticker && ticker}
		placeholder="Enter Ticker..."
	/>
}

const FundSelectionSelector = ({ onTickerTextChange, updateTickerStatus, tickerOption }) => {

	const remote = useRemote();
	const [offers, setOffers] = useState([]);
	const [selectedOffer, setSelectedOffer] = useState(null);

	const [selectedFund, setSelectedFund] = useState(null);

	useEffect(() => {
		remote.get("/funds/offers").then(response => {
			setOffers(response.filter(o => tickerOption.offerNumbers.includes(o.offerNumber)))
		});
	}, [])

	const chooseFund = (fund, index) => {
		setSelectedFund(fund);
		onTickerTextChange({ currentTarget: { value: fund.fundNumber } }, index, () => updateTickerStatus(true));
	};

	return (
		<>
			<DropDown clickable fullWidth label={tickerOption.selectionName} value={selectedOffer && selectedOffer.offerName}>
				{offers.map((option, index) => (
					<li key={index} onClick={() => setSelectedOffer(option)}>
						{option.offerName}
					</li>
				))}
			</DropDown>
			{selectedOffer &&
				<DropDown clickable fullWidth label={tickerOption.subSelectionName} value={selectedFund && selectedFund.fundName} labelClassName='mt-l'>
					{selectedOffer.funds.map((option, index) => (
						<li key={index} onClick={() => chooseFund(option, index)}>
							{option.fundName}
						</li>
					))}
				</DropDown>
			}
		</>
	)
}