import React from 'react';
import { AlertCircle, Tool } from 'react-feather';
import Checkbox from '../../../components/Checkbox';
import Table from '../../../components/Table/Table';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import ExpandableTableRow from '../../../components/Table/ExpandableTableRow';
import FlexWrapper from '../../FlexWrapper';
import ParamField from './ParamField'
import SpotCheckActions from './SpotCheckActions';


const TemplateCustomPublishActions = ({ data, addActionToTemplate, removeAction, selected, templateId, setSelectedPublishActions, companyId, editMode }) => {
	const colWidths = [40 + "%", 60 + "%"];
	const selectedPublishActionIds = selected.map(a => a.stepId);

	const showParams = (action) => {
		if (!action) return null;
		if (action.stepId === 9 || action.stepId === 10) return <SpotCheckActions action={action} templateId={templateId} label={action.stepId === 9 ? 'Providers' : 'Email Recipiants'}/>
		return (
			<FlexWrapper gap="xl" direction="column" align="start">
				{action.params.map((param, idx) => <ParamField key={idx} param={param} action={action} template={templateId} setSelectedPublishActions={setSelectedPublishActions} />)}
			</FlexWrapper>)
	}

	const dataMap = () => data && data.map((action, index) => {
		const active = selectedPublishActionIds && selectedPublishActionIds.includes(action.id);
		const noExpandable = action.unwired
		const selectedInstance = active ? selected.find(a => a.stepId === action.id) : null;
		let className = `cursor-pointer row-border-top`;
		let invalid = false;

		if (!active && action.companyId === companyId) {
			return <tr key={action.id} className={`row-border-top ${noExpandable ? 'disabled-row' : ''}`}>
				<td><Checkbox checked={active} disabled={noExpandable || !editMode} label={action.displayName} onChange={() => addActionToTemplate(action)} /></td>
				{action.id === 8 ?
					<td><SpotCheckActions action={action} templateId={templateId} /></td> :
					<td>{action.description}</td>
				}
				<td></td>
			</tr>
		}

		if (selectedInstance !== null) {
			for (let param of selectedInstance.params) {
				if (param.required) {
					const val = selectedInstance.paramValues[param.name];
					if (!val || (val && val.length === 0)) {
						invalid = true;
						break;
					}
				}
			}
		}

		return (action.id === 8 ?
			<tr className="cursor-pointer no-hover">
				<td><Checkbox checked={active} disabled={!editMode} label={action.displayName} onChange={(e) => removeAction(action, e)} stopClick /></td>
				<td><SpotCheckActions action={action} templateId={templateId} /></td>
			</tr> : 
				action.companyId === companyId &&
				<ExpandableTableRow key={action.id} className={className} expandContent={showParams(selectedInstance)} dontExpand={noExpandable}>
					<td><Checkbox checked={active} disabled={!editMode} label={action.displayName} onChange={() => removeAction(action)} /></td>
					<td>{action.description} </td>
					<td>{invalid ? <AlertCircle /> : null}</td>
				</ExpandableTableRow>
		);
	})

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Custom Publish Actions" icon={<Tool />} className='mb-m' />
				<Table colWidths={colWidths} dataMap={dataMap()} />
			</PageGridItem>
		</>
	);
}


export default TemplateCustomPublishActions;