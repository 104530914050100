import React from 'react';
import { Typography } from "./BrandsStyles";

const TypographyAdmin = ({brand, details, updateDetails}) => {

    const colours = {brand} || ['#ccccff','#aa66cc','#99ff33']

    const styles = [
        "Banner Header",
        "Banner Sub-header",
        "Paragraph",
        "List element",
        "Heading 1",
        "Heading 2",
        "Heading 3",
        "Image/Table heading",
        "Image/Table footer",
        "Footer"
    ]

    return <>
        {styles.map(s => <Typography key={s} brandColours={colours} label={s} details={details} updateDetails={updateDetails}/>)}
    </>

}


export default TypographyAdmin;