import React from "react";
import { PageContentBanner } from "../Shared/Page/Page";
import DateRangeInput from "../CraigsLibraryPage/DateRangeInput";
import { withCraigsDocsFilters } from "../CraigsLibraryPage/withCraigsDocs";
import TickerListSearchField from "../../components/Input/TickerListSearchField";
import moment from "moment";
import SideBarExtensionHeader from "../../components/SideBar/SideBarExtension/SideBarExtensionHeader";
import Divider from "../../components/Divider";


const DraftsLibraryPageContentBanner = ({ filters, setFilters, header, icon }) => {
	const { ticker, from, to } = filters;
	return (
		<PageContentBanner divider gap="m" dividerThinkness="m">
			<SideBarExtensionHeader header={header} icon={icon} style={{ marginBottom: 0, paddingRight: 0 }} noTabs/>
			<Divider vertical height="24px" marginTop="0.25rem"/>
			<TickerListSearchField
				minWidth
				size="small"
				placeholder="Search"
				value={ticker}
				onChange={(ticker) => setFilters({ ...filters, ticker })}
				isClearable
				onClear={() => setFilters({ ...filters, ticker: null })}
			/>
			<DateRangeInput value={{ from, to }} onChange={({ from, to }) => setFilters({ ...filters, from: from ? moment(from).toISOString() : "", to: to ? moment(to).toISOString() : "" })} />
		</PageContentBanner>
	);
}

export default withCraigsDocsFilters(DraftsLibraryPageContentBanner);