import React, { useState } from "react";
import Page from "../Shared/Page/Page";
import DraftsLibraryPageContentBanner from "./DraftsLibraryPageContentBanner";
import RequestsLibraryPageBody from "./RequestsLibraryPageBody";
import { UserCheck } from "react-feather";

const RequestsLibrary = () => {
	const [filters, setFilters] = useState({ ticker: null, from: null, to: null });

	return (
		<Page fixedBanner id="drafts-library-page" style={{paddingLeft: "1rem"}}>
			<DraftsLibraryPageContentBanner filters={filters} setFilters={setFilters} header="Requests" icon={<UserCheck color="#0E8EFD"/>} />
			<RequestsLibraryPageBody filters={filters} />
		</Page>
	);
}

export default RequestsLibrary;
