import { Fragment, useEffect, useState } from "react";
import { onDrop, useRemote } from "../../../Utils/Utils"
import { toastWarning } from "../../../components/popups/Toast";
import { FontPreview } from 'font-preview';
import { AlertCircle, CheckCircle, ChevronDown, ChevronUp, Trash2 } from "react-feather";
import { toastSuccess } from '../../../components/popups/Toast'
import Dropzone from '../../../components/Dropzone/Dropzone';
import Button from "../../../components/Buttons/Button";
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import Divider from "../../../components/Divider";
import "./FontAdmin.scss";

const FontAdmin = ({ brand, details, updateDetails }) => {

    const remote = useRemote();

    const [showUpload, setShowUpload] = useState(false);
    const [fontUploading, setFontUploading] = useState(false);
    const [fontConfig, setFontConfig] = useState({})
    const [expandedFonts, setExpandedFonts] = useState(null)
    const [overwriteExpanded, setOverwriteExpanded] = useState(null)

    useEffect(() => {
        if (details && details.fonts && details.fonts.length > 0) {
            if (overwriteExpanded) {
                setExpandedFonts(overwriteExpanded)
            } else {
                const expandedFontssArray = new Array(details.fonts.length).fill(false)
                setExpandedFonts(expandedFontssArray)
            }
        } else setExpandedFonts([])

    }, [details, overwriteExpanded]);


    useEffect(() => {
        if (!details || !details.fonts) {
            return;
        }
        // console.log("Fonts available have changed, resetting fonts config")
        const newConfig = {
            custom: {
                families: details.fonts.filter(font => font.hasTTf && font.hasWoff2).map(font => font.name),
                urls: details.fonts.filter(font => font.hasTTf && font.hasWoff2).map(font => font.cssImport)
            }
        }
        // console.log("New config is ", newConfig);
        setFontConfig(newConfig)
    }, [details])


    const uploadFont = (files, which, fontId, passedExpanded, idx) => {
        onDrop(files, `/brand/font/${brand.id}/${fontId}/${which}`, {
            method: 'put',
            setLoading: setFontUploading,
            onUploadComplete: (data) => {
                setFontUploading(false)
                setShowUpload(false);
                try {
                    const parsed = JSON.parse(data.target.response);
                    if (parsed.success) {
                        const deets = { ...details };
                        const index = deets.fonts.findIndex(x => x.id === fontId);
                        deets.fonts[index] = parsed.data;
                        setOverwriteExpanded(passedExpanded)
                        updateDetails(deets);
                        toastSuccess("Font saved")
                    } else {
                        toastWarning(parsed.message);
                    }
                    //setStyle(parsed.data);
                } catch (e) {
                    console.log("Error ", e)
                    toastWarning("Error uploading file")
                }
            },
        })
    }

    const createFont = () => {
        remote.post(`/brand/font/${brand.id}`).then(r => {
            const deets = { ...details };
            deets.fonts.push(r);
            updateDetails(deets);
            setExpandedFonts([...expandedFonts, true])
        })
    }

    const deleteFont = (fontId) => {
        remote.delete(`/brand/font/${brand.id}/${fontId}`).then(response => {
            const formattedFonts = response?.data?.map(font => {
                const fontObject = {
                    cssImport: `/api/fonts/${font.fontName}.css`,
                    familyName: font.familyName,
                    hasTTf: font.ttf ? true : false,
                    hasWoff2: font.woff2 ? true : false,
                    id: font.id,
                    name: font.fontName,
                    parent: font.parentFont,
                    variantName: font.variant,
                    variants: null
                }
                return fontObject
            })
            const deets = { ...details, fonts: formattedFonts };
            updateDetails(deets);
        })
    }

    const fontReadyCallback = (props) => {
        console.log("Fonts are ready ", props);
    }

    const previewWoff2 = (font) => {
        return <FontPreview
            fontUrl={`/api/fonts/brand/font/${font.id}.woff2`}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        />
    }

    const previewTtf = (font) => {
        return <FontPreview
            fontUrl={`/api/fonts/brand/font/${font.id}.ttf`}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        />
    }

    function updateExpandedFonts(index, boolean) {
        const newArray = expandedFonts.map((f, idx) => {
            if (idx === index) return boolean
            else return f
        })
        setExpandedFonts(newArray)
    }

    return (
        <>
            <WebfontLoader onStatus={fontReadyCallback} config={fontConfig}>
                <div className="second-sidebar-left-label">Fonts</div>
                {details && details.fonts && details.fonts.length > 0 && details.fonts.map((f, index) => {
                    return <Fragment key={f.id}>
                        <div className="custom-font-wrapper">
                            <div className="cf-header">
                                <p className="cf-name">{f.name ? f.name : "New font"}</p>
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <Trash2 className="cf-delete-icon colour-icon colour-hover-danger" size={20} onClick={() => deleteFont(f.id)} />
                                    {expandedFonts && expandedFonts[index] ?
                                        <ChevronUp className="cf-expand-icon colour-icon" size={20} onClick={() => updateExpandedFonts(index, false)} /> :
                                        <ChevronDown className="cf-expand-icon colour-icon" size={20} onClick={() => updateExpandedFonts(index, true)} />
                                    }
                                </div>
                            </div>
                            <div className="cf-inner" style={{ display: expandedFonts && expandedFonts[index] ? 'flex' : 'none' }}>
                                <div className="cf-block">
                                    <div className={`block-header${f.hasTTf ? ' colour-text-positive-dark' : ' colour-text-danger'}`}>{f.hasTTf ? <CheckCircle className="colour-text-positive-dark" size={16} /> : <AlertCircle className="colour-text-danger" size={16} />} TTF file</div>
                                    {!f.hasTTf && (
                                        <Dropzone onDrop={(files) => uploadFont(files, "ttf", f.id, expandedFonts, index)} uploading={fontUploading} showUploadingSpinner displayMessage={"Drop TTF here"} />
                                    )}
                                    {f.hasTTf && previewTtf(f)}
                                </div>
                                <Divider />
                                <div className="cf-block">
                                    <div className={`block-header${f.hasWoff2 ? ' colour-text-positive-dark' : ' colour-text-danger'}`}>{f.hasWoff2 ? <CheckCircle className="colour-text-positive-dark" size={16} /> : <AlertCircle className="colour-text-danger" size={16} />} WOFF2 file</div>
                                    {!f.hasWoff2 && (
                                        <Dropzone
                                            onDrop={(files) => uploadFont(files, "woff2", f.id, expandedFonts, index)}
                                            uploading={fontUploading}
                                            showUploadingSpinner
                                            displayMessage={"Drop Woff2 here"}
                                        />
                                    )}
                                    {f.hasWoff2 && previewWoff2(f)}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                })}
            </WebfontLoader>

            {showUpload && (
                <>
                    {/* <TextInput></TextInput> */}
                    <Dropzone onDrop={uploadFont} uploading={fontUploading} showUploadingSpinner displayMessage={"Drop a valid ttf file here, or click to upload"} />
                </>
            )}
            {/*         <Button onClick={() => setShowUpload(!showUpload)} style={{ width: 'fit-content' }}>{showUpload ? "Cancel" : "Create new font"}</Button> */}
            <Button onClick={createFont} style={{ width: "fit-content" }}>
                Create new font
            </Button>
        </>
    );
}

export default FontAdmin;