import React, { useEffect, useState } from 'react';
import { UserPlus } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import Table from '../../../components/Table/Table';
import { Mail, ChevronUp, ChevronDown } from 'react-feather';
import PageGridItem from '../../Shared/Page/PageGridItem';
import IconCounter from '../../../components/IconCounter';
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import useWindowDimensions, { useRemote, undoDeleteUser } from '../../../Utils/Utils';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import { toastDanger, UndoToastMessage, toast } from '../../../components/popups/Toast';
import { useHistory } from 'react-router-dom';

const UsersLists = ({ }) => {

	const remote = useRemote();
	const history = useHistory();

	const [data, setData] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: true })
	const [dataDisplaying, setDataDisplaying] = useState([])
	const [loading, setLoading] = useState(true)
	const { width } = useWindowDimensions();

	useEffect(() => {
		getContacts()
	}, []);

	useEffect(() => {
		sortTable(null)
	}, [data, filteredData])

	const sortTable = (column) => {
		if (column === null) {
			setDataDisplaying(hasSearchTerm ? filteredData : data)
			return
		}
		let tableData = hasSearchTerm ? filteredData : data
		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		switch (column) {
			case "Name":
				tableData.sort((a, b) => ascending ? a.firstName.localeCompare(b.firstName) : b.firstName.localeCompare(a.firstName))
				break;
			case "Company":
				tableData.sort((a, b) => {
					const user1 = a.companyName || "-"
					const user2 = b.companyName || "-"
					return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
				})
				break;
			case "Role":
				tableData.sort((a, b) => {
					const user1 = a.role || "-"
					const user2 = b.role || "-"
					return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
				})
				break;
			case "Email":
				tableData.sort((a, b) => {
					const user1 = a.email || "-"
					const user2 = b.email || "-"
					return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
				})
				break;
			case "DistributionLists":
				tableData.sort((a, b) => ascending ? a.distributionLists - b.distributionLists : b.distributionLists - a.distributionLists)
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setDataDisplaying(tableData)
	}

	const getContacts = () => {
		remote.get("/crm/contacts")
			.then((contacts) => {
				let sortedContacts = contacts.sort((userA, userB) => {
					let nameA = userA.firstName + " " + userA.lastName
					let nameB = userB.firstName + " " + userB.lastName
					return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
				})
				return sortedContacts
			})
			.then((contacts) => setData(contacts))
			.then(() => setLoading(false))
	}


	const addContactToData = (contact) => {
		let contactsToSort = [...data]
		let sortedContacts = contactsToSort.sort((userA, userB) => {
			let nameA = userA.firstName + " " + userA.lastName
			let nameB = userB.firstName + " " + userB.lastName
			return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
		})
		setData(sortedContacts)
	}

	const removeUser = (e, user) => {
		e.stopPropagation();
		remote.get(`/crm/contact/${user.id}/lists`).then((subscribedLists) => {
			remote.delete(`/crm/contacts/${user.id}`)
				.then(() => {
					let newArray = []
					data.forEach((contact) => {
						if (contact.id !== user.id) {
							newArray.push(contact)
						}
					})
					setData(newArray)
				}).then(() =>
					toast(<UndoToastMessage message="User removed" onClick={() => { undoDeleteUser(user, subscribedLists, addContactToData) }} />, { autoClose: 10000 })
				)
				.catch(() => {
					toastDanger("User could not be removed")
				})
		})
	}

	const colWidths = ["260px", "260px", "150px", "260px", "155px", "150px"]
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Name")} >
					Name
					{sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Company")} >
					Company
					{sortingColumn && sortingColumn.column === "Company" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Company" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Role")} >
					Role
					{sortingColumn && sortingColumn.column === "Role" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Role" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Email")} >
					Email
					{sortingColumn && sortingColumn.column === "Email" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Email" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header right' onClick={() => sortTable("DistributionLists")} >
					Distribution Lists
					{sortingColumn && sortingColumn.column === "DistributionLists" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "DistributionLists" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		""
	]
	const dataMap = (dataToMap) => dataToMap && dataToMap.map((user, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => history.push(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + user.id)}>
				<td>{user.firstName + " " + user.lastName}</td>
				<td>{user.companyName || "-"}</td>
				<td>{user.role || "-"}</td>
				<td><div className="truncate">{user.email || "-"}</div></td>
				<td className="text-align-right">
					<IconCounter icon={<Mail />} dataAvailable={true} type="read" count={user.distributionLists} />
				</td>
				<TableActionIconGroup
					download={{ hide: true }}
					edit={{ onClick: (e) => { e.stopPropagation(); history.push(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + user.id + "?edit=true") } }}
					duplicate={{ onClick: (e) => { e.stopPropagation(); history.push(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + user.id + "?create=true") } }}
					remove={{ onClick: (e) => removeUser(e, user) }}
				/>
			</tr>
		);
	})
	return (
		<Page fixedBanner showScrollbar>
			{/* Modal */}
			<PageContentBanner divider gap="m">
				<Button icon={<UserPlus />} onClick={() => history.push(ROUTE_PATHS[PAGES.CRM] + "/users/user/create")}>{width < 900 ? "" : "Create User"}</Button>
				<SearchTermFilter
					size="small"
					width="l"
					placeholder={`Search users ${loading ? "" : "(" + data.length + ")"}`}
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["companyName", "firstName", "lastName", "preferredName", "email", "role"]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/>
			</PageContentBanner>
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<Table
						minWidth={1000}
						tableStyle={{maxWidth: 1300}}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap(dataDisplaying)}
						noResults={{ message: "No users found", subMessage: "Try adding a new user." }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default UsersLists;