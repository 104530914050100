import React from 'react';
import { Plus } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { gicsSectors } from '../../../Utils/DummyData';
import SelectSearchField from '../../../components/Input/SelectSearchField';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import TickerSectorItem from '../../../components/TickerSectorItem';
import useWindowDimensions from '../../../Utils/Utils';
import "./SectorList.scss";

const SectorList = ({}) => {
	// Table widths and headers
	const colWidths = [40 + "%", 30 + "%", 25 + "%"];
	const headers = ["Name", "Region", ""];
	const { width } = useWindowDimensions();
	// const [showBrowserModal, setShowBrowserModal] = useState(false);

	// Table map
	const dataMap = () => gicsSectors.map((sector, idx) => {
		return (
			<tr key={idx}>
				<td><TickerSectorItem label={sector.name} image={sector.image} /></td>
				<td>{sector.region}</td>
				<TableActionIconGroup
					disabled
				/>
			</tr>
		)
	});

	return (
		<Page fixedBanner>
			{/* Modal */}
			{/* {showBrowserModal && (
				<ImageBrowserModal
					handleClose={() => setShowBrowserModal(false)}
				/>
			)} */}
			<PageContentBanner divider gap="l">
				<Button icon={<Plus />} disabled={true} >{width < 900 ? "" : "Add Sector"}</Button>
				<SelectSearchField size="small" width="m" placeholder="Try searching 'Utilities'" isClearable/>
			</PageContentBanner>
			<PageContentBodyGrid rowGap="xl">
				<PageGridItem colSpan="12">
					{/* Suggestion for initial page load */}
					<Table id="sector-table" minWidth={600} colWidths={colWidths} headers={headers} dataMap={dataMap()} />
					{/* Empty state message*/}
					{/* <NoResultsMessage /> */}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default SectorList;