import React, { useEffect, useState } from 'react';
import { UserPlus } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Table from '../../../components/Table/Table';
import useWindowDimensions, { impersonate, useRemote, useStorage } from '../../../Utils/Utils';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import TableActionIcon from '../../../components/Table/TableActionIcon';
import { useHistory } from 'react-router-dom';


const UserManagement = ({ me }) => {
	// Table widths and headers
	const authorsColWidths = [3 + "%", 20 + "%", 20 + "%", 12 + "%", 20 + "%", 15 + "%", 10 + "%"];
	const authorHeaders = ["", "Name", "Title", "Role", "Email", "Phone", "Author"];

	const { width } = useWindowDimensions();

	const [data, setData] = useState([]);
	const [authors, setAuthors] = useState([]);
	const [viewAuthors, setViewAuthors] = useState(false);
	const [roles, setRoles] = useState([])
	const [filteredData, setFilteredData] = useState([]);
	const [hasFilterSearchTerm, setHasFilterSearchTerm] = useState(false);

	const remote = useRemote();
	const storage = useStorage();
	const history = useHistory()
	const params = new URLSearchParams(window.location.search);
	const authorsQuery = params.get('authors');

	const [headers, setHeaders] = useState(["Name", "Title", "Role", "Email", "Phone", "Author"]);
	const [colWidths, setColWidths] = useState([30 + "%", 20 + "%", 5 + "%", 5 + "%", 20 + "%", 20 + "%"]);

	const [hasImpersonate, setHasImpersonate] = useState(false);


	useEffect(() => {
		if (authorsQuery) {
			setViewAuthors(true)
		}
		remote.get('/roles/all').then((roles) => {
			setRoles(roles)
			remote.get('/crm/users').then((val) => {
				const usersWithRoleName = val.map((user, idx) => {
					const userRole = roles.filter(role => role.id == user.roleId)[0];
					return { ...user, roleName: userRole ? userRole.displayName : "-" };
				});

				setData(usersWithRoleName)
				setAuthors(usersWithRoleName
					.filter((person) => person.isAuthor)
					.sort((a, b) => a.sortOrder - b.sortOrder)
				)
			});
		});
	}, [])

	useEffect(() => {
		if (!me || !me.perms) {
			setHasImpersonate(false);
		}
		if (me.perms.includes("IMPERSONATE")) {
			setHasImpersonate(true);
		} else {
			setHasImpersonate(false);
		}
	}, [me])

	useEffect(() => {


		if (hasImpersonate) {
			setColWidths([20 + "%", 20 + "%", 20 + "%", 15 + "%", 10 + "%", 10 + "%", 5 + "%"]);
			setHeaders(["Name", "Title", "Email", "Phone", "Author", "Role", ""]);

		} else {
			setColWidths([25 + "%", 20 + "%", 20 + "%", 15 + "%", 10 + "%", 10 + "%"]);
			setHeaders(["Name", "Title", "Email", "Phone", "Author", "Role"]);

		}

	}, [hasImpersonate]);

	const isAuthor = (data) => {
		return data ? "Yes" : "No";
	};

	// Table map
	const dataMap = (dataToMap) => dataToMap
		.sort((a, b) => a.name.localeCompare(b.name))
		.map((user, idx) => {
			const userRole = roles.filter(role => role.id == user.roleId)[0];
			return (
				<tr key={idx} className="cursor-pointer" onClick={(e) => { history.push(ROUTE_PATHS[PAGES.ADMIN] + "/user-management/" + user.email) }}>
					<td>{user.name}</td>
					<td>{user.title}</td>
					<td>{user.email}</td>
					<td>{user.phone}</td>
					<td>{isAuthor(user.isAuthor)}</td>
					<td>{userRole ? userRole.displayName : "-"}</td>
					{hasImpersonate && <td onClick={(e) => { e.preventDefault(); e.stopPropagation(); impersonate(user, remote, storage) }}>
						<TableActionIcon
							colour="primary"
							toolTip="Impersonate"
							icon={<Supervisor />}
						/>
					</td>}
				</tr>
			)
		});

	const DragHandle = sortableHandle(() => (
		<span className="material-symbols-outlined">drag_handle</span>
	));
	const Supervisor = sortableHandle(() => (
		<span className="material-symbols-outlined">supervisor_account</span>
	));

	const SortableItem = sortableElement(({ user, idx }) => {
		const userRole = roles.filter(role => role.id == user.roleId)[0];
		return (
			<tr key={idx} className="cursor-pointer dragged" onClick={() => history.push(ROUTE_PATHS[PAGES.ADMIN] + "/user-management/" + user.email + "?authors=true")} >
				<td><DragHandle /></td>
				<td>{user.name}</td>
				<td>{user.title}</td>
				<td>{userRole ? userRole.displayName : "-"}</td>
				<td>{user.email}</td>
				<td>{user.phone}</td>
				<td>{isAuthor(user.isAuthor)}</td>
				<td>{user.sortOrder}</td>
			</tr>
		)
	});

	const SortableList = sortableContainer(({ data, email }) => {
		return (
			<tbody>
				{data
					.map((user, idx) => (
						<SortableItem
							key={idx}
							index={idx}
							user={user}
							email={email}
						/>
					))}
			</tbody>
		);
	});


	const sortEnd = (move) => {
		const userDragged = authors[move.oldIndex]
		const updatedData = {
			email: userDragged.email,
			sortOrder: move.newIndex + 1,
		};

		remote.put("/crm/user/order", updatedData)
			.then((updated) => {
				setAuthors(updated);
			})
			.catch((error) => {
				console.error("Error updating sortOrder:", error);
			});
	};

	return (
		<Page fixedBanner>
			<PageContentBanner divider gap="m">
				<Button disabled icon={<UserPlus />}>{width < 900 ? "" : "Create User"}</Button>
				<SearchTermFilter
					size="small"
					width="l"
					placeholder={`Search Users`}
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["name", "email", "title", "phone", "roleName"]}
					hasSearchTerm={setHasFilterSearchTerm}
					disabled={viewAuthors}
				/>
				<Button onClick={() => setViewAuthors(!viewAuthors)}>{width < 900 ? "" : (!viewAuthors ? "Authors" : "All Users")}</Button>
			</PageContentBanner>
			<PageContentBodyGrid>
				<PageGridItem colSpan="12">
					<Table minWidth={900}
						colWidths={viewAuthors ? authorsColWidths : colWidths}
						headers={viewAuthors ? authorHeaders : headers}
						dragDrop={viewAuthors ? true : false}
						dataMap={viewAuthors ? <SortableList data={authors} useDragHandle onSortEnd={sortEnd} /> : dataMap(hasFilterSearchTerm ? filteredData : data)}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	)
}

export default UserManagement;