import React, {forwardRef, useState} from "react";
import "./TextInput.scss";
import TextFieldLabel from "./TextFieldLabel";
import { X } from "react-feather";

const TextInput = forwardRef(({ name, size, width, fullWidth, inputClassName, className, label, invalid, helperText, disabled, icon, type, placeholder, onChange, clearText, isClearable, variant, ...rest }, ref) => {
	const [value, setValue] = useState("")
	const clearIconVisible = isClearable && value && value !== "";
	return (
		<div
			className={`text-input-wrapper${invalid ? " text-input-wrapper-invalid" : ""}${icon ? " icon-active" : ""}${isClearable ? " clear-icon-active" : ""}${
				size === "small" ? " text-input-small" : ""
			}${className ? " " + className : ""}${width ? " text-field-width-" + width : ""}${fullWidth ? " input-full-width" : ""}`}
		>
			{label && <TextFieldLabel>{label}</TextFieldLabel>}
			<input
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e);
				}}
				ref={ref}
				{...rest}
				type={type ? type : "text"}
				name={name}
				disabled={disabled}
				placeholder={placeholder}
				className={`text-input${size === "small" ? " input-size-small" : ""}${inputClassName ? " " + inputClassName : ""}${variant === "light" ? " input-variant-light" : variant === "bordered-light" ? " input-variant-bordered-light" : ""}`}
			/>
			<div className="text-field-icon-wrapper">{icon && icon}</div>
			{clearIconVisible && <X className="clear-icon" onClick={() => { clearText(); setValue("")}} />}
			{helperText && <div className="text-input-helper-text">{helperText}</div>}
		</div>
	);
})

export default TextInput 