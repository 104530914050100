import React, { useEffect, useState } from 'react';
import { getIcon, useRemote } from "../../../Utils/Utils"
import { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import FlexWrapper from '../../FlexWrapper';
import RadioButton from '../../../components/RadioButton';
import Button from '../../../components/Buttons/Button';
import ImageGalleryWrapper from '../../../components/popups/ImageGalleryWrapper';
import sectorImages from "../../../images/sectors"
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import LoadingIcon from '../../../components/LoadingIcon';
import Table from '../../../components/Table/Table';
import PublicationPreview from './PublicationPreview';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import { format, parseISO } from 'date-fns';
import "./PublicationSettings.scss"

const PublicationSettings = ({ data, setData, models, updateVisibility, updateImageKey }) => {
	const [publication, setPublication] = useState({})
	const [loading, setLoading] = useState(true)
	const [summary, setSummary] = useState("")
	const [originalSummary, setOriginalSummary] = useState("");
	const [publicationPortalVisible, setPublicationPortalVisible] = useState(false)
	const [originalPublicationPortalVisible, setOriginalPublicationPortalVisible] = useState(false)
	const [uploading, setUploading] = useState([])
	const [originalSelectedImage, setOriginalSelectedImage] = useState(null)
	const [selectedImage, setSelectedImage] = useState(null)
	const [showSectorImageOptions, setShowSectorImageOptions] = useState(false)
	const [summaryKey, setSummaryKey] = useState(1);
	const [modelMap, setModelMap] = useState([]);
	const [loadingSelectedImage, setLoadingSelectedImage] = useState(true)

	const remote = useRemote()
	let assetList = data && data.assets && Object.keys(data.assets)

	useEffect(() => {
		const tempModelMap = {};
		models.forEach(m => {
			tempModelMap[m.tickerName] = m;
		});
		setModelMap(tempModelMap);
	}, models);

	useEffect(() => {
		setPublication(data);
		setLoading(false);
		setSummary(data.summary);
		setOriginalSummary(data.summary);
		setPublicationPortalVisible(data.publishedToPortal);
		setOriginalPublicationPortalVisible(data.publishedToPortal);
		remote.get(`/documents/document/${data.docId}/details`)
			.then((resp) => {
				if (resp?.portal?.imageUrl?.type === "stored") {
					// console.log('#1 resp.portal', resp.portal)
					setSelectedImage(resp?.portal?.imageUrl?.url);
					setOriginalSelectedImage(resp?.portal?.imageUrl?.url);
					setLoadingSelectedImage(false)
				} else {
					// console.log('#2 resp.summary', resp.summary)
					setSelectedImage("/api/public/images/note/" + resp.summary.docId + ".png");
					setOriginalSelectedImage("/api/public/images/note/" + resp.summary.docId + ".png");
					setLoadingSelectedImage(false)
				}
			})
	}, [data])

	const publish = async () => {
		if (publicationPortalVisible) return;
		setLoading(true);

		let changes = {}
		if (summary !== originalSummary) {
			changes.summary = summary
		}

		await remote.put(`/crm/portal/${data.docId}`).then(resp => {
			data = resp;
			toastSuccess("Document available in portal");
			setLoading(false);
			setPublicationPortalVisible(true)
			setOriginalPublicationPortalVisible(true)
			setSummary(resp.summary ? resp.summary : "");
			setOriginalSummary(resp.summary ? resp.summary : "");
			setSummaryKey(summaryKey + 1);
			updateVisibility(true)
			remote.get(`/documents/document/${data.publicationId}/details`).then(doc => {
				// console.log('put portal resp', resp)
				// console.log('get doc resp', doc)
				setData({...doc.summary, summary: resp.summary, imageUrl: resp.imageUrl})
			})

		}).catch(e => {
			console.log(e);
			toastDanger("Error publishing document to portal");
			setLoading(false);
		})
	}

	const unpublish = () => {
		if (!publicationPortalVisible) {
			return;
		}
		remote.delete(`/crm/portal/${data.docId}`).then(resp => {
			if (resp.removed) {
				toastSuccess("Document removed from portal");
				setPublicationPortalVisible(false)
				setOriginalPublicationPortalVisible(false)
				updateVisibility(false)
			}
		}).catch(e => {
			console.log(e);
			toastDanger("Error removing document from portal");
		})
	}

	const changesMade = summary !== originalSummary
		|| originalPublicationPortalVisible !== publicationPortalVisible
		|| originalSelectedImage !== selectedImage

	const onDrop = (files, asset) => {
		setUploading([...uploading, asset])
		const tooBig = [];
		const wrongFileType = [];
		let anyUploaded = false;
		for (var i = 0; i < files.length; i++) {
			let file = files[i];
			let name = file.name;

			if (file.size > 5 * 1024 * 1024) {
				console.log(file, "too big")
				tooBig.push(file);
				continue;
			}

			anyUploaded = true;
			let xhr = new XMLHttpRequest();
			xhr.open('post', `/api/crm/tickers/${asset}/upload-model/`, true);
			xhr.addEventListener('load', e => {
				if (xhr.status >= 200 && xhr.status < 300) {
					onUploadComplete(e, asset);
				} else {
					console.log(xhr);
				}
			});
			xhr.upload.addEventListener('progress', e => {
				if (e.lengthComputable) {
					let complete = (e.loaded / e.total * 100 | 0);
					if (complete === 100) {
						//TODO onscreen reporting
						console.log("Processing ...");
					} else {
						console.log("Finished");
					}
				}
			});

			xhr.setRequestHeader("Content-Type", file.type);
			xhr.setRequestHeader("Content-Disposition", "attachment; filename=" + name);
			xhr.send(file);
		}
		if (tooBig.length > 0 || wrongFileType.length > 0) {
			toastDanger(`Upload Failed ${tooBig.length > 0 ? "file too large" : ""}${tooBig.length > 0 && wrongFileType.length > 0 ? ", " : ""}${wrongFileType.length > 0 ? "wrong file type" : ""}`)
			if (!anyUploaded) {
				setUploading(uploading.filter((id) => id !== asset))
			}
		}
	}

	const onUploadComplete = (e, asset) => {
		const resp = JSON.parse(e.target.response);
		if (!resp.success) {
			setUploading(uploading.filter((id) => id !== asset))
		} else {
			toastSuccess("Successfully uploaded " + resp?.data?.model?.filename)
			const tempModelMap = { ...modelMap };
			const data = resp.data;
			tempModelMap[data.model.tickerName] = data.model;
			tempModelMap[data.model.tickerName].visibleInPortal = false
			setModelMap(tempModelMap);
			setUploading(uploading.filter((id) => id !== asset))
		}
	}

	const onImageSelect = (image) => {
		setSelectedImage(image)
		setShowSectorImageOptions(false)
	}

	const saveChanges = () => {
		let sendObj = originalSelectedImage === selectedImage ? { summary: summary } : { summary: summary, image: selectedImage }
		remote.post(`/crm/portal/${data.docId}`, sendObj).then(resp => {
			setSummary(resp.summary ? resp.summary : "");
			setOriginalSummary(resp.summary ? resp.summary : "");
			setSummaryKey(summaryKey + 1);
			if(sendObj.image) {
				setOriginalSelectedImage(resp.imageUrl.url)
				updateImageKey()
			}
			toastSuccess("Changes Saved")
			setData({...data, summary: resp.summary})

		})
	}

	const onModelClick = (e, asset) => {
		const fileInput = document.createElement('input');
		fileInput.type = 'file';
		fileInput.multiple = true;
		fileInput.onchange = (event) => { onDrop(event.target.files, asset) };
		fileInput.click();
	};

	const setModelPortalVisible = (asset, which) => {
		remote.post(`/crm/tickers/${asset}/model/visible`, { visible: which }).then(m => {
			const tempModelMap = { ...modelMap };
			tempModelMap[m.tickerName] = m;
			setModelMap(tempModelMap);
		})
	}

	const tickerDataMap = assetList && assetList.map(asset => <tr key={asset}>
		<td>{data.assets[asset]}</td>
		<td onClick={(e) => onModelClick(e, asset)} className={`upload-model-cell ${modelMap && modelMap[data.assets[asset]] ? "" : "no-model"}`}>
			{getIcon("modelDownloads", "16px", null, null, modelMap && modelMap[data.assets[asset]] ? null : "#999DAD")}
			<div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
				{modelMap && modelMap[data.assets[asset]] ? modelMap[data.assets[asset]].filename : "Upload"}
			</div>
			{uploading.includes(asset) && <LoadingIcon size={16} />}
		</td>
		<td className="publication-settings-td" >{modelMap && modelMap[data.assets[asset]] ? modelMap && modelMap[data.assets[asset]].portalFilename : "No file"}</td>
		<td>
			<FlexWrapper align="center" gap="m" >
				<RadioButton disabled={!modelMap[data.assets[asset]]} checked={modelMap[data.assets[asset]] && modelMap[data.assets[asset]].visibleInPortal === true} label="Yes" onClick={() => setModelPortalVisible(asset, true)} />
				<RadioButton disabled={!modelMap[data.assets[asset]]} checked={modelMap[data.assets[asset]] && modelMap[data.assets[asset]].visibleInPortal === false} label="No" onClick={() => setModelPortalVisible(asset, false)} />
			</FlexWrapper>
		</td>
	</tr>
	)

	return (
		<div className='publication-settings-wrapper' key={"portal-details-" + data.docId}>
			<PageContentBanner>
				<FlexWrapper gap="m">
					<DetailSectionHeader header="Portal Information" />
				</FlexWrapper>
			</PageContentBanner>

			{loading ? <LoadingIcon centered size={40} />
				: <PageContentBodyGrid gridColCount="6" subGrid >
					<PageGridItem col="1 / span 3" >
						<PageContentBodyGrid gridColCount="6" subGrid>
							<PageGridItem col="1 / span 2">
								<FlexWrapper align="center" gap="m" className="mt-m">
									<label style={{ fontSize: "0.85rem", fontWeight: "400", whiteSpace: "nowrap" }}>Note Visible in Portal</label>
									<RadioButton checked={publicationPortalVisible === true} label="Yes" onClick={() => publish()} />
									<RadioButton checked={publicationPortalVisible === false} label="No" onClick={() => unpublish()} />
								</FlexWrapper>
							</PageGridItem>
							{originalPublicationPortalVisible && <>
								<PageGridDivider col="1 / span 6" />
								<PageGridItem col="1 / span 6" >
									<FlexWrapper direction="column" gap="m" align="start" >
										<DetailSectionHeader header="Portal Preview" />
										<PublicationPreview
											hoverUpload={publication && publication.type && publication.type === "Sector Note"}
											onImageClick={() => { publication && publication.type && publication.type === "Sector Note" && setShowSectorImageOptions(true) }}
											title={publication.title}
											displayName={publication.ticker}
											summary={summary}
											imageUrl={selectedImage}
											imageUrlLoading={loadingSelectedImage}
											analystName={publication.author}
											formattedDate={publication.date && format(parseISO(publication.date), "dd/MM/yyyy")}
											tickers={assetList.map((key) => publication?.assets[key])}
											onSummaryChange={(summary) => { originalPublicationPortalVisible && setSummary(summary) }}
											editable={originalPublicationPortalVisible}

										/>
										<FlexWrapper direction="row" justify="space-between" fullWidth >
											<Button onClick={() => saveChanges()} disabled={!changesMade} >Save Changes</Button>
											<p style={{ marginRight: "1rem" }}>{summary && summary.length}/300</p>
										</FlexWrapper>
									</FlexWrapper>
								</PageGridItem>
								<PageGridDivider col="1 / span 6" />
								<PageGridItem col="1 / span 6" style={{ marginBottom: "1rem" }}>
									<FlexWrapper direction="column" gap="m" align="start" >
										<DetailSectionHeader header={assetList && assetList.length > 1 ? "Asset Models" : "Asset Model"} />
										<Table colWidths={["15%", "35%", "30%", "20%"]} compact headers={["Ticker", "File Upload", "Portal Name", "Model Visible in Portal"]} dataMap={tickerDataMap}>s
										</Table>
									</FlexWrapper>
								</PageGridItem>
							</>
							}

						</PageContentBodyGrid>
					</PageGridItem>
					{originalPublicationPortalVisible && <PageGridItem col="4 / span 3" >
						<div style={{ display: "flex" }}>
							{
								showSectorImageOptions &&
								<FlexWrapper gap="s" direction="column" align="flex-start">
									<ImageGalleryWrapper size="l" onImageSelect={onImageSelect} images={[{ images: sectorImages }]} />
									<Button style={{ marginTop: "0.5rem" }} variant="secondary" className="upload-button" onClick={() => { setShowSectorImageOptions(false) }} >
										Close
									</Button>
								</FlexWrapper>
							}
						</div>
					</PageGridItem>}
				</PageContentBodyGrid>
			}
		</div>
	);
}

export default PublicationSettings;