import React, { useState } from 'react';
import { Trash, TrendingUp } from 'react-feather';
import Table from '../../../components/Table/Table';
import PageGridItem from '../Page/PageGridItem';
import TableActionIcon from '../../../components/Table/TableActionIcon';
import useWindowDimensions from '../../../Utils/Utils';
import DetailSectionHeader from '../DetailSection/DetailSectionHeader';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';
import TickerSectorItem from '../../../components/TickerSectorItem';
import FlexWrapper from '../../FlexWrapper';
import SelectSearchField from '../../../components/Input/SelectSearchField';
import ExtensionLabel from '../../../components/Labels/ExtensionLabel';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { toast } from 'react-toastify';
import SearchField from '../../../components/Input/SearchField';
import Tooltip from '../../../components/popups/Tooltip';
import { useHistory } from 'react-router-dom';

const MiniSectorList = ({data, stopRedirect}) => {
	const { width } = useWindowDimensions();
	const xl = width > 2250;
	const l = width > 1900;
	const s = width > 1500;
	const colWidths = s ? [60 + "%", 30 + "%", 10 + "%"] : [90 + "%", 10 + "%"];
	const [filteredData, setFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const history = useHistory();

	const dataMap = () => data && data.map((sector, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => stopRedirect ? null : history.push(ROUTE_PATHS[PAGES.ADMIN] + "/sectors/sector")}>
				<td>
					<div className="truncate">
						<TickerSectorItem size="m" label={sector.name} extensionLabel={sector.createdBy ? sector.createdBy : "System"} image={sector.image} />
					</div>
				</td>
				{/* Show/hide ticker column based on page width */}
				{s ? (
					<td>
						<div className="truncate">
							{sector.tickers[0]}
							<Tooltip label={sector.tickers.map((ticker, idx) => <span key={idx}>{ticker + (idx === sector.tickers.length - 1 ? "" : ", ")} </span>)}>
								<ExtensionLabel label={`+${sector.tickers.length - 1} more`} />
							</Tooltip>
						</div>
					</td>
				) : (
					""
				)}
				<td className="table-action-icons transition-fade-in-slow">
					<TableActionIcon hover="danger" icon={<Trash />} toolTip="Remove sector" onClick={(e) => {e.stopPropagation(); toast("Sector removed");}} />
				</td>
			</tr>
		);
	})

	const noData = false;
	const noSearchResults = false;
	
	return (
		<>
			<PageGridItem col="1 / span 6" justifyContent="between" gap="m" wrap>
				<DetailSectionHeader icon={<TrendingUp />} header="Sector Selection" />
				<FlexWrapper gap="s">
					{!noData && (
						//  Using this search field to stop page from crashing. Should be replaced with the same search component as the other mini tables.
						<SelectSearchField
							size="small"
							width={xl ? "l" : l ? "m" : "s"}
							placeholder="Search sectors"
						/>
					)}
					<SearchField
						placeholder="Add sector"
						size="small"
						width={noData && xl ? "l" : l ? "m" : "s"} 
					/>
				</FlexWrapper>
			</PageGridItem>
			<PageGridItem col="1 / span 6">
				{noData ? (
					<SuggestionMessage
						message={
							<>
								No sectors added yet. <span className="text-link">Try adding one now.</span>
							</>
						}
					/>
				) : noSearchResults ? (
					<NoResultsMessage compact />
				) : (
					<Table colWidths={colWidths} dataMap={dataMap(hasSearchTerm ? filteredData : data )} />
				)}
			</PageGridItem>
		</>
	);
}

export default MiniSectorList;