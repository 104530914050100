import React from 'react';
import PropTypes from 'prop-types';
import "./StatusWidget.scss";

const StatusWidget = ({ label, message, icon, status }) => {
	return (
		<div className={`status-widget-wrapper${status ? " " + status : ""}`}>
			<div className="icon-wrapper">{icon}</div>
			<div className="info-wrapper">
				{label && <span className="label-wrapper">{label} </span>}
				{message && <span className="message-wrapper">{message}</span>}
			</div>
		</div>
	);
}

StatusWidget.propTypes = {
	message: PropTypes.string.isRequired,
	icon: PropTypes.element.isRequired,
	status: PropTypes.oneOf(['danger','']),
};

export default StatusWidget;
