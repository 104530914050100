import React, { useEffect, useState } from 'react';
import { ChevronUp, ChevronDown, Edit2 } from 'react-feather';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import PageGridDivider from '../../Shared/Page/PageGridDivider'
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import withRouteChange from '@threeskye/route-change';
import SearchTermFilter from '../../../components/Input/SearchTermFilter'
import FundsNavigation from './FundsNavigation';

const SchemesList = ({ changeRoute }) => {

	const remote = useRemote();
	const [sortingColumn, setSortingColumn] = useState({ column: 'dateAdded', ascending: true })
	const [schemeData, setSchemeData] = useState([]);
	const [hasSchemeSearchTerm, setHasSchemeSearchTerm] = useState(false);
	const [filteredSchemeData, setFilteredSchemeData] = useState([])

	const schemeDataDisplaying = hasSchemeSearchTerm ? filteredSchemeData : schemeData

	useEffect(() => {
		remote.get("/funds/schemes").then(setSchemeData);
	}, [remote])

	// Clear Sorting
	useEffect(() => {
		setSortingColumn({ column: "dateAdded", ascending: true })
	}, [filteredSchemeData])

	const toggleSort = (column) => {
		if (column === null) {
			setSortingColumn({ column: "dateAdded", ascending: true })
			return
		}

		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		setSortingColumn({ column: column, ascending: ascending })
	}

	const sortTable = (dataToSort) => {
		if (sortingColumn.column === null) {
			return dataToSort
		}
		if (!dataToSort) {
			return dataToSort
		}

		let tableData = dataToSort
		const ascending = sortingColumn.ascending
		const column = sortingColumn.column;

		tableData.sort((a, b) => ascending ? a[column].localeCompare(b[column]) : b[column].localeCompare(a[column]))

		return tableData
	}

	// Table widths and headers
	const schemesColWidths = ['auto', 15 + '%', 15 + '%', 15 + '%', '50px'];
	const schemesHeaderLabels = [
		{ heading: 'Scheme Name', field: 'schemeName' },
		{ heading: 'Scheme Number', field: 'schemeNumber' },
		{ heading: 'Date Added', field: 'dateAdded' },
		{ heading: 'Last Modified', field: 'lastModified' }
	]

	const schemesHeaders = schemesHeaderLabels.map((column) => {
		return (
			{
				text: (
					<div className='sorting-header' onClick={() => toggleSort(column.field)} >
						{column.heading}
						{sortingColumn?.column === column.field && sortingColumn?.ascending === true ?
							<ChevronUp className='colour-text-primary' size={12} /> :
							<ChevronDown className={sortingColumn?.column === column.field ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
						}
					</div>
				)
			}
		)
	})

	const schemeDataMap = (data) => data && data.map((scheme, idx) => {
		const { schemeName, schemeNumber, dateAdded, lastModified } = scheme

		return (
			<>
				<tr key={idx} className='cursor-pointer' onClick={() => changeRoute(ROUTE_PATHS[PAGES.ADMIN] + '/schemes-and-funds/schemes/' + scheme.schemeNumber)}>
					<td>{schemeName}</td>
					<td>{schemeNumber}</td>
					<td>{dateAdded} </td>
					<td>{lastModified}</td>
					<td style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'right' }}>
						<Edit2 className='colour-text-primary' />
					</td>
				</tr>
			</>);
	})

	const sortedDataToDisplay = sortTable(schemeDataDisplaying)
	const dataToDisplay = !!sortedDataToDisplay ? schemeDataMap(sortedDataToDisplay) : null

	return (
		<Page fixedBanner>
			<PageContentBanner divider dividerThinkness="m">
				<FundsNavigation activeHeader='Schemes' />
			</PageContentBanner>
			<PageContentBodyGrid rowGap="xl">
				<PageGridItem colSpan='12' gap='xl' direction='col'>
					<SearchTermFilter
						size='small'
						width='m'
						placeholder='Search'
						isClearable
						dataSet={schemeData}
						setFilteredData={setFilteredSchemeData}
						path={['schemeName', 'schemeNumber']}
						hasSearchTerm={(boolean) => setHasSchemeSearchTerm(boolean)}
					/>
					<PageGridDivider />
				</PageGridItem>
				<PageGridItem colSpan='12' >
					<Table
						minWidth={1100}
						tableStyle={{ maxWidth: 1200 }}
						colWidths={schemesColWidths}
						headers={schemesHeaders}
						dataMap={dataToDisplay}
						id='scheme-and-funds-expandable-table'
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page >
	)

}

export default withRouteChange(SchemesList);