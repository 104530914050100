import React, { useEffect, useState } from 'react';
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import { LogOut } from 'react-feather';
import Divider from '../Divider';
import PopupMenu from '../PopupMenu';
import PopupMenuItem from '../PopupMenuItem';
import ProfileImage from '../ProfileImage';
import "./ProfileMenu.scss";
import { useRemote } from '../../Utils/Utils';
import { useHistory } from 'react-router-dom';

const ProfileMenu = ({ changeRoute, user, tenants }) => {
	const remote = useRemote();
	const history = useHistory();

	const [currentTenant, setCurrentTenant] = useState(null);
	const [otherTenants, setOtherTenants] = useState(null);

	useEffect(() => {
		setCurrentTenant(tenants.find(t => t.companyId === user.companyId));
		setOtherTenants(tenants.filter(t => t.companyId !== user.companyId))
	}, [tenants, user])


	const logout = () => {
		console.log("Profile menu doing logout")
		remote.get("/crm/logout").then((obo) => {
			//if we're impersonating, we'll have new access.
			if (obo && obo.data) {
				window.location.href = `/?${Math.random()}`;
			} else {
				history.push("/no-access");
			}
		})
	}

	const switchUser = (user) => {
		remote.post(`/crm/tenants/${user.companyId}`).then(() => {
			window.location.href = `/?${Math.random()}`;
		})
	}


	return (
		<>
			<PopupMenu padding width="300px">
				<div className="profile-menu-header-container">
					<ProfileImage user={user} src={user && "data:image/jpeg;base64," + user.photo} />
					<div className="profile-menu-details">
						<div className="profile-header">{user && user.name}</div>
					</div>
				</div>
				<Divider />
				<PopupMenuItem label="Profile" onClick={() => changeRoute(ROUTE_PATHS[PAGES.SETTINGS] + "/profile")} />
				<Divider />
				{currentTenant && <>
					<div className="section-header">Account</div>
					<div className='current-account-company'>
						<p >
							{currentTenant.companyName}
						</p>
						<p className='current-account-role'>
							{currentTenant?.roleDTO?.displayName}
						</p>
					</div>
				</>}
				{otherTenants && otherTenants.length > 0
					? <>
						<Divider />
						<div className="section-header">Switch Account</div>
						{otherTenants && otherTenants.map((acc, idx) => {
							return <div onClick={() => switchUser(acc)} key={acc.companyId} className="profile-menu-option">
								<PopupMenuItem
									label={acc.companyName}
									subLabel={acc?.roleDTO?.displayName}
								/>
							</div>
						})}
					</>
					: ""
				}
				<Divider />
				<PopupMenuItem label="Logout" icon={<LogOut />} onClick={logout} />
			</PopupMenu>
		</>
	)
}

export default ProfileMenu;